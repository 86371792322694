<template>
	<div class="release app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="发布跟单" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="release_body app_scroll">
			<van-form class="release_form">
				<van-cell-group inset>
					<van-field v-model="oForm.number" type="number" label="保障赔率" placeholder="请输入保障赔率" :border="false"
						input-align="right" @update:model-value="fUpdateNumber" @blur="fBlurNumber">
						<template #extra>
							<div class="release_form_tip">
								{{oCPData.oNCData.profitProtectRateMin}}-{{oCPData.oNCData.profitProtectRateMax}}
							</div>
						</template>
					</van-field>
					<div class="release_form_custom">
						<span class="release_form_custom_a">方案内容</span>
						<span class="release_form_custom_b">开赛后可见</span>
					</div>
					<van-field class="release_form_flex" v-model="oForm.message" rows="2" autosize label="方案宣言"
						:disabled="oCPData.oNCData.permission" type="textarea" maxlength="1000"
						placeholder="信心出击，让你稳稳的收米！" show-word-limit @update:model-value="fUpdateMessage"
						@blur="fBlurMessage" />
				</van-cell-group>
			</van-form>
			<div class="release_tips">
				<div>注意事项：</div>
				<div>1. 保障佣金是为了保障平台用户在跟单下单的时候，赔率变化太大，而损失利益!</div>
				<div>2. 跟单者的中奖金额&#60;投注金额*保障佣金倍数，不用支付佣金！</div>
				<div>3. 发单人可在平台规定比例内设置保障佣金倍数，不设置默认最 低保障佣金倍数！</div>
			</div>
		</div>

		<div class="release_bottom">
			<van-checkbox class="release_bottom_checkbox" v-model="oForm.isAgree" color="#FF7671"
				checked-color="#FF7671" label-disabled>
				我已阅读并同意<span class="app_agreement" @click="fGoGeneralTxt('issueBill')">《发单协议》</span><span
					class="app_agreement" @click="fGoGeneralTxt('information')">《信息同步协议》</span>
			</van-checkbox>
			<div class="release_bottom_flex" v-if="!oForm.isHasPlanId">
				<div class="release_bottom_flex_a">
					投注金额：<em>{{oCPData.oNCData.oOrderData.totalFee}}</em>元
				</div>
				<span class="release_bottom_flex_b" @click="fCreatePlan">彩店出票</span>
			</div>
			<div class="release_bottom_flex" v-else>
				<span class="release_bottom_flex_b release_bottom_flex_c" @click="fReleasePlan">发布跟单</span>
			</div>
		</div>

		<h-dialog-c title="提示" dialogKey="dialogCA" :show="oCPData.oYCData.dialogCA.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCA.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="release_tips_dialog">
				您已绑定{{oPlanDetail.shopName}}，支付成功后在该店出票。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCB" :show="oCPData.oYCData.dialogCB.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCB.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCB.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				当前投注盘口变化,是否继续完成支付?
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCC" :show="oCPData.oYCData.dialogCC.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCC.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCC.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="release_tips_dialog">
				您有跟单方案未支付佣金，请先支付完佣金后再投注。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCD" :show="oCPData.oYCData.dialogCD.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="release_tips_dialog release_dialogCD">
				您确定要放弃支付吗？
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCE" :show="oCPData.oYCData.dialogCE.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCE.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCE.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="release_tips_dialog">
				现在返回页面修改信息将全部丢失，确定返回吗？
			</div>
		</h-dialog-c>
		<h-dialog-c title="信息同步" dialogKey="dialogCF" :show="oCPData.oYCData.dialogCF.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCF.showCancelButton"
			:cancelButtonText="oCPData.oYCData.dialogCF.cancelButtonText"
			:confirmButtonText="oCPData.oYCData.dialogCF.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="release_tips_dialog">
				本店将根据<em class="cFF7671">《信息同步协议》</em>将相关信息进行同步并打开信息同步开关，同步开关打开后其他玩家才可以跟单，如需关闭信息同步请到“我的 > 设置 > 方案信息同步”
				中进行关闭
			</div>
		</h-dialog-c>
		<h-actionSheet-a :title="oPayRelated.title" :show="oPayRelated.showDialog" :payDetail="oPayRelated.payDetail"
			:oButton="oPayRelated.oButton" sRechargeRedirect="plan" @fBeforeClose="fBeforeCloseActionSheetA" @fSubmit="fSubmitActionSheetA">
		</h-actionSheet-a>
	</div>
</template>

<script>
	import {
		reactive,
		computed,
		onActivated,
		inject
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import HActionSheetA from "../../components/HActionSheet/HActionSheetA";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import {
		fnIsHasHistory,
		fnGoGeneralTxt
	} from "@/utils/router.js";
	import {
		fnKeepDecimals
	} from "@/utils/public.js";
	import tipsContent from '@/utils/tipsContent';
	import {
		apiGetProfitProtectRateScope,
		apiPlanLotteryShop,
		apiPlanCreate,
		apiOrderPayPlateCheck,
		apiOrderPay,
		apiPlanIssueDocumentary
	} from '@/api/allplay.js';
	import {
		apiSyncRolePlan
	} from '@/api/useInfo.js';

	export default {
		name: "Release",
		components: {
			HActionSheetA,
			HDialogC
		},
		setup() {
			const route = useRoute();
			const router = useRouter();
			const route_query = router.currentRoute.value.query;

			const store = useStore(); // 使用useStore方法
			const fGlobalReload = inject("fGlobalReload");
			const fGoGeneralTxt = fnGoGeneralTxt; //跳转通用文本页面
			const UserInfo = computed(() => store.state.userInfo.userInfo);

			const oCPData = reactive({
				oNCData: {
					profitProtectRateMin: 0.1,
					profitProtectRateMax: 10000,
					permission: false,
					oOrderData: {}
				},
				oYCData: {
					dialogCA: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCB: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCC: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "去支付佣金"
					},
					dialogCD: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCE: {
						isSure: false,
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCF: {
						showDialog: false,
						showCancelButton: true,
						cancelButtonText: "暂不同步先发单",
						confirmButtonText: "开启同步并发单"
					},
				}
			});
			// 方案详情
			const oPlanDetail = reactive({
				isDisableCreate: true, // 是否可以彩店出票
				shopName: "", // 绑定彩票店名称
				calculate: {
					multiple: 1, // 下注倍数
					minBetMultiple: 1, // 最低下注倍数
					minTotalFee: null, // 最低下注金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					totalFee: 0, // 方案投注金额
				}
			});
			// 获取保障培露范围
			const fGetProfitProtectRateScope = () => {
				apiGetProfitProtectRateScope().then(res => {
					let tData = res.result ? res.result : {};
					oForm.number = oCPData.oNCData.profitProtectRateMin = tData.profitProtectRateMin ? tData
						.profitProtectRateMin : 0.1;
					oCPData.oNCData.profitProtectRateMax = tData.profitProtectRateMax ? tData
						.profitProtectRateMax : 10000;
					oCPData.oNCData.permission = tData.permission ? tData.permission : false; //true禁用，false正常
				})
			}

			// 表单数据
			const oForm = reactive({
				isHasPlanId: false,
				number: null,
				message: null,
			});

			// 保障赔率更新
			const fUpdateNumber = (Value) => {
				oForm.number = fnKeepDecimals(Value, 1);
			};
			// 保障赔率输入框失焦
			const fBlurNumber = () => {
				// 保留最小金额
				let tNumber = fnKeepDecimals(oForm.number, 1);
				if (tNumber <= Number(oCPData.oNCData.profitProtectRateMin)) {
					oForm.number = oCPData.oNCData.profitProtectRateMin;
				} else if (tNumber > Number(oCPData.oNCData.profitProtectRateMax)) {
					oForm.number = oCPData.oNCData.profitProtectRateMax;
				}
			};
			// 方案宣言--更新
			const fUpdateMessage = (Value) => {
				let tReg =
					/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g
				oForm.message = Value.replace(tReg, '');
			};
			// 方案宣言--失焦
			const fBlurMessage = () => {
				let tReg =
					/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g
				oForm.message = oForm.message.replace(tReg, '');
			};
			// 彩店出票--创建方案
			const fCreatePlan = () => {
				if (!oForm.isAgree) return Toast({
					message: tipsContent.disagreeRead + "《发单协议》和《信息同步协议》哦",
					position: 'bottom'
				});
				// 是否开启同步
				if (UserInfo.value.isSync) {
					fCreatePlanTwo();
				} else {
					oCPData.oYCData.dialogCF.showDialog = true;
				}
			};

			// 彩店出票--创建方案--第二步
			const fCreatePlanTwo = () => {
				Toast({
					type: "loading"
				});
				apiPlanLotteryShop({
					roleId: UserInfo.value.roleId,
					userId: UserInfo.value.userId,
					lotteryTypeEnum: oCPData.oNCData.oOrderData.lotteryTypeEnum
				}).then(res => {
					let tData = res.result;
					let tShopName = "";
					if (tData.canBet) {
						for (let i = 0, j = tData.lotteryShopList.length; i < j; i++) {
							if (i === 0) {
								tShopName = "" + tData.lotteryShopList[i].shopName
							} else {
								tShopName = tShopName + "/" + tData.lotteryShopList[i].shopName
							}
						}
						oPlanDetail.shopName = tShopName;
						oCPData.oYCData.dialogCA.showDialog = true;
					}
				})
			};

			// 支付相关信息
			const oPayRelated = reactive({
				payDetail: {
					isPlateChange: false, // 方案盘口是否发生改变
					availableAmount: 0, // 店内余额
					totalFee: 0, // 方案金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					planId: null, // 方案ID
					isEnough: false, // 是否可以支付
				},
				oButton: {
					isShow: true,
					text: "确定"
				},
				showDialog: false, //是否展示弹框
				title: "彩店出票"
			});

			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				if (dialogKey === "dialogCA") {
					if (action === "confirm") {
						// 确定创建订单
						Toast({
							type: "loading"
						});
						apiPlanCreate({
							...oCPData.oNCData.oOrderData,
							canCopy: 1,
							profitProtectRate: oForm.number,
							planName: oForm.message ? oForm.message : "信心出击，让你稳稳的收米！"
						}).then(res => {
							let tData = res.result ? res.result : {};
							oPayRelated.payDetail.isPlateChange = tData.isPlateChange ? tData.isPlateChange :
								false;
							oPayRelated.payDetail.availableAmount = tData.availableAmount ? tData
								.availableAmount : 0;
							oPayRelated.payDetail.totalFee = tData.totalFee ? tData.totalFee : 0;
							oPayRelated.payDetail.prePrizeAmount = tData.prePrizeAmount ? tData
								.prePrizeAmount : 0;
							oPayRelated.payDetail.prePrizeAmountMin = tData.prePrizeAmountMin ? tData
								.prePrizeAmountMin : 0;
							oPayRelated.payDetail.planId = tData.planId;
							oPayRelated.payDetail.isEnough = tData.isEnough;
							oCPData.oYCData.dialogCA.showDialog = false;
							store.dispatch("allPlay/CleanAllPlayData");
							oPayRelated.showDialog = true;
							fn(false)
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCB") {
					if (action === "confirm") {
						fGoPay(fn);
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCB.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCC") {
					if (action === "confirm") {
						oCPData.oYCData.dialogCC.showDialog = false;
						fn(false);
						// 跳转支付佣金界面
						router.replace({
							path: '/recordGD',
							query: {
								redirect: 'mine'
							}
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCC.showDialog = false;
						fn(false);
					}
				} else if (dialogKey === "dialogCD") {
					if (action === "confirm") {
						oPayRelated.showDialog = false;
						oCPData.oYCData.dialogCD.showDialog = false;
						fn(false);
						// 跳转方案列表页
						router.replace({
							path: '/plan'
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCD.showDialog = false;
						fn(false);
					}
				} else if (dialogKey === "dialogCE") {
					if (action === "confirm") {
						oCPData.oYCData.dialogCE.showDialog = false;
						oCPData.oYCData.dialogCE.isSure = true;
						fnIsHasHistory("/");
						fn(false)
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCE.showDialog = false;
						fn(false);
					}
				} else if (dialogKey === "dialogCF") {
					if (action === "confirm") {
						Toast({
							type: "loading"
						});
						apiSyncRolePlan({
							isSync: true,
							roleId: UserInfo.value.roleId,
						}).then(() => {
							UserInfo.value.isSync = true;
							oCPData.oYCData.dialogCF.showDialog = false;
							fn(false);
							fCreatePlanTwo();
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCF.showDialog = false;
						fn(false);
						fCreatePlanTwo();
					}
				}
			};
			/* fBeforeCloseActionSheetA ActionSheetA关闭之前
			 * action 操作的按钮（confirm：确定；cancel：取消；overlay：点击遮罩层）
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fBeforeCloseActionSheetA = (action, fn) => {
				if (action === 'confirm') {
					oPayRelated.showDialog = false;
					return fn(false)
				} else if (action === 'cancel') {
					oCPData.oYCData.dialogCD.showDialog = true;
					return fn(true)
				}
			};
			/* fSubmitActionSheetA 支付的动作面板中的支付按钮
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fSubmitActionSheetA = async (fn) => {
				// 该订单是否可支付
				if (!oPayRelated.payDetail.isEnough) return;

				Toast({
					type: "loading"
				});
				let tOrderPayPlateCheckResult = await apiOrderPayPlateCheck({
					id: oPayRelated.payDetail.planId
				});
				if (tOrderPayPlateCheckResult.result || oPayRelated.payDetail.isPlateChange) {
					return oCPData.oYCData.dialogCB.showDialog = true;
				}

				fGoPay(fn);
			};

			// 开始支付
			const fGoPay = (fn) => {
				Toast({
					type: "loading"
				});
				// 订单支付
				apiOrderPay({
					balance: oPayRelated.payDetail.totalFee,
					otherFee: 0,
					payType: 1,
					planId: oPayRelated.payDetail.planId,
					roleId: UserInfo.value.roleId,
					userId: UserInfo.value.userId
				}).then(res => {
					oCPData.oYCData.dialogCB.showDialog = false;
					fn(false);
					let tData = res.result;
					// 是否支付成功
					if (tData["payResult"]) {
						// 成功
						Toast({
							message: tData.message ? tData.message : "支付成功",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						});
					} else if (tData["orderUnPayReasonEnum"] === "HAVE_UN_PAY_FEE") {
						// 失败:需要支付佣金--打开跳转到佣金弹框
						oCPData.oYCData.dialogCC.showDialog = true;
					} else {
						Toast({
							message: tData.message ? tData.message : "支付失败",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						})
					}
				})
			};

			// 发布方案
			const fReleasePlan = () => {
				if (!oForm.isAgree) return Toast({
					message: tipsContent.disagreeRead + "《发单协议》和《信息同步协议》哦",
					position: 'bottom'
				});

				Toast({
					type: "loading"
				});
				apiPlanIssueDocumentary({
					currentRoleId: UserInfo.value.roleId,
					planId: route_query.planId,
					planName: oForm.message ? oForm.message : "信心出击，让你稳稳的收米！",
					profitProtectRate: oForm.number
				}).then(res => {
					console.log(res);
					Toast({
						message: res.message ? res.message : "发布跟单成功！",
						position: 'bottom'
					});
					fnIsHasHistory("/");
				})
			};

			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else {
					fGetProfitProtectRateScope();
					if (route_query.planId) {
						oForm.isHasPlanId = true;
					} else {
						oForm.isHasPlanId = false;
						oCPData.oNCData.oOrderData = store.state.allPlay.orderData;
					}
				}
			});
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (!oCPData.oYCData.dialogCE.isSure) {
					if (to.path === "/plan" || to.path === "/planDetail" || to.path === "/generalTxt" || to
						.path === "/recharge" || to.path === "/recordGD" || to.path === "/web") {
						return true
					} else {
						const timer = setTimeout(() => {
							oCPData.oYCData.dialogCE.showDialog = true;
							clearTimeout(timer);
						}, 100)
						return false;
					}
				} else {
					if (to.path === "/optimize") {
						to.meta.resetType = "0"
					}
					return true
				}
			})
			return {
				oCPData,
				oForm,
				oPayRelated,
				oPlanDetail,

				fUpdateNumber,
				fBlurNumber,
				fUpdateMessage,
				fBlurMessage,

				fCreatePlan,
				fReleasePlan,

				fBeforeCloseActionSheetA,
				fSubmitActionSheetA,
				fBeforeClose,

				fLeft,
				fGoGeneralTxt
			}
		}
	}
</script>

<style scoped lang="scss">
	.release {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.release_body {
			padding: 0.9066rem 0.32rem;

			.release_tips {
				font-size: 0.32rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #979797;
				line-height: 0.4533rem;

				&>div {
					margin-bottom: 0.2133rem;
				}
			}

			.release_dialogCD {
				text-align: center;
			}
		}

		.release_bottom {
			padding: 0.56rem 0.4rem;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;

			.release_bottom_flex {
				margin-top: 0.72rem;

				display: flex;
				align-items: center;
				justify-content: space-between;

				.release_bottom_flex_a {
					display: flex;
					align-items: center;
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;

					&>em {
						font-size: 0.48rem;
						font-weight: bold;
						color: #E2335D;
					}
				}

				.release_bottom_flex_b {
					padding: 0.24rem 1.1733rem;

					background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
					border-radius: 25px;

					text-align: center;
					font-size: 0.3733rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 0.5333rem;
				}

				.release_bottom_flex_c {
					flex: 1;
				}
			}
		}

		.release_tips_dialog {
			padding: 0.2666rem 0.64rem 0.88rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}
	}
</style>
