<template>
	<div class="footBall app_page app_flex">
		<div class="app_title_c">
			<van-sticky>
				<van-nav-bar left-arrow right-text="玩法规则" @click-right="fRight" safe-area-inset-top>
					<template #left>
						<img class="app_title_c_left_icon" src="~@/assets/icon/icon-008.png" @click="fLeft">
						<img class="app_title_c_left_img" src="~@/assets/images/img_012.png">
						<span class="app_title_c_left_title">混合过关</span>
					</template>
					<template #right>
						<span class="app_title_c_right_title">玩法规则</span>
					</template>
				</van-nav-bar>
			</van-sticky>
		</div>
		<div v-if="aMatch.length" class="footBall_body app_scroll">
			<van-collapse v-model="nActiveNames" :border="false">
				<van-collapse-item v-for="(item,index) in aMatch" class="footBall_day"
					:class="{'footBall_dayC': nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
					:key="index">
					<template #title>
						<div class="footBall_day_title">
							<div class="footBall_day_title_a">
								<span class="footBall_day_title_a_date">
									{{item.issueNo}}
								</span>
								<span class="footBall_day_title_a_week">
									{{fIsToday(item.issueNo) ? "今日" : item.weekStr}}
								</span>
							</div>
							<span v-show="nActiveNames.indexOf(item.issueNo) !== -1" class="footBall_day_title_b">
								共<em>{{item.matchDetailResponseList.length}}</em>场比赛
							</span>
						</div>
						<div v-show="nActiveNames.indexOf(item.issueNo) === -1" class="footBall_day_close">
							当日共 <em>{{item.matchDetailResponseList.length}}</em> 场比赛
						</div>
					</template>
					<template #right-icon>
						<div class="footBall_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) !== -1">
							<span class="footBall_day_rIcon_text">收起</span>
							<img class="footBall_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
						</div>
						<div class="footBall_day_rIcon" v-show="nActiveNames.indexOf(item.issueNo) === -1">
							<span class="footBall_day_rIcon_text">展开</span>
							<img class="footBall_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
						</div>
					</template>
					<div class="footBall_options" v-for="(itemA, indexA) in item.matchDetailResponseList" :key="indexA">
						<div class="footBall_options_title">
							<div class="footBall_options_title_a">
								<span>{{itemA.hostShortName}}</span>
								<img src="~@/assets/icon/icon-011.png">
								<span>{{itemA.guestShortName}}</span>
							</div>
							<div class="footBall_options_title_b">{{itemA.deadlineStr}}截止</div>
							<div class="footBall_options_title_c">
								<img class="footBall_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
								<div>
									<em>{{itemA.gameNo}}</em>
									<span>{{itemA.gameName}}</span>
								</div>
								<img class="footBall_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
							</div>
						</div>

						<div class="footBall_options_content">
							<div class="footBall_options_content_three">
								<div class="footBall_options_content_three_typeSPF">{{itemA.isSupportSpfGg ? 0 : ""}}</div>
								<div class="footBall_options_content_three_typeOptions" v-if="itemA.isSupportSpfGg">
									<template v-for="(itemB,indexB) in oCPData.spfMatchResponse" :key="indexB">
										<div :class="{'footBall_check': itemA.spfMatchResponse && itemA.spfMatchResponse[itemB.select],
											'footBall_single' : indexB === 0 && itemA.isSupportSpfDg}"
											@click="fSelectOptions(index,indexA,itemB,'spfMatchResponse')">
											<p>{{itemB.name}}</p>
											<p>
												{{itemA.spfMatchResponse && itemA.spfMatchResponse[itemB.key]}}
											</p>
										</div>
									</template>
								</div>
								<div v-else class="footBall_options_none">
									当前玩法不支持投注
								</div>
							</div>
							<div class="footBall_options_content_three">
								<div class="footBall_options_content_three_typeRQSPF">
									{{itemA.isSupportRqspfGg ? (itemA.rqspfPlate>0 ? '+' + itemA.rqspfPlate : itemA.rqspfPlate) : ""}}
								</div>
								<div class="footBall_options_content_three_typeOptions" v-if="itemA.isSupportRqspfGg">
									<template v-for="(itemB,indexB) in oCPData.rqspfMatchResponse" :key="indexB">
										<div :class="{'footBall_check': itemA.rqspfMatchResponse && itemA.rqspfMatchResponse[itemB.select],
											'footBall_single' : indexB === 0 && itemA.isSupportRqspfDg}"
											@click="fSelectOptions(index,indexA,itemB,'rqspfMatchResponse')">
											<p>{{itemB.name}}</p>
											<p>{{itemA.rqspfMatchResponse && itemA.rqspfMatchResponse[itemB.key]}}
											</p>
										</div>
									</template>
								</div>
								<div v-else class="footBall_options_none">
									当前玩法不支持投注
								</div>
							</div>
							<div class="footBall_options_other"
								:class="{'footBall_options_other_select' : itemA.selectMore > 0}"
								@click="fOpenMore(index,indexA,itemA)">更多选项</div>
						</div>
					</div>
				</van-collapse-item>
			</van-collapse>
		</div>
		<div v-else class="footBall_none app_scroll">
			<van-empty class="footBall_none_empty" :image="require('@/assets/images/img_021.png')" description="暂无赛事" />
		</div>

		<div class="footBall_bottom">
			<div class="footBall_bottom_left">
				<img src="~@/assets/icon/icon-015.png" @click="fCleanAll">
				<span v-show="aMatchSelect.length>0">已选 <em
						class="footBall_bottom_left_em2">{{aMatchSelect.length}}</em> 场</span>
				<span v-show="aMatchSelect.length===0">非 <em class="footBall_bottom_left_em1">单关</em> 至少选择 <em
						class="footBall_bottom_left_em2">2</em>
					场比赛</span>
			</div>
			<span class="footBall_bottom_right " :class="{'footBall_bottom_right--disabled': bIsDisabled}"
				@click="fBet">
				立即投注
			</span>
		</div>

		<h-dialog-b :title="dialogB.title" dialogKey="dialogB" :show="dialogB.showDialog"
			:showCancelButton="dialogB.showCancelButton" :confirmButtonText="dialogB.confirmButtonText"
			@fBeforeClose="fBeforeClose">
			<div class="footBall_allOption app_scroll">
				<div class="footBall_allOption_way">
					<div class="footBall_allOption_way_title">
						胜平负 / 让球
					</div>
					<div class="footBall_allOption_way_content">
						<div class="footBall_allOption_way_content_three">
							<div class="footBall_allOption_way_content_three_typeSPF">{{dialogB.match.isSupportSpfGg ? 0 : ""}}</div>
							<div v-if="dialogB.match.isSupportSpfGg" class="footBall_allOption_way_content_three_typeOptions
								footBall_allOption_way_content_three_typeSPFOptions">
								<template v-for="(itemB,indexB) in oCPData.spfMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.spfMatchResponse && dialogB.match.spfMatchResponse[itemB.select],
										'footBall_single' : indexB === 0 && dialogB.match.isSupportSpfDg}"
										@click="fSelectMoreOptions(itemB, 'spfMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.spfMatchResponse && dialogB.match.spfMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>
							<div v-else class="footBall_allOption_none footBall_allOption_noneAB">
								当前玩法不支持投注
							</div>
						</div>
						<div class="footBall_allOption_way_content_three">
							<div class="footBall_allOption_way_content_three_typeRQSPF">
								{{dialogB.match.isSupportRqspfGg ? (dialogB.match.rqspfPlate > 0 ? '+' + dialogB.match.rqspfPlate : dialogB.match.rqspfPlate) : ""}}
							</div>
							<div v-if="dialogB.match.isSupportRqspfGg" class="footBall_allOption_way_content_three_typeOptions
								footBall_allOption_way_content_three_typeRQSPFOptions">
								<template v-for="(itemB,indexB) in oCPData.rqspfMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.rqspfMatchResponse && dialogB.match.rqspfMatchResponse[itemB.select],
										'footBall_single' : indexB === 0 && dialogB.match.isSupportRqspfDg}"
										@click="fSelectMoreOptions(itemB, 'rqspfMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.rqspfMatchResponse && dialogB.match.rqspfMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>
							<div v-else class="footBall_allOption_none footBall_allOption_noneCD">
								当前玩法不支持投注
							</div>
						</div>
					</div>
				</div>

				<div class="footBall_allOption_way">
					<div class="footBall_allOption_way_title">
						<img v-if="dialogB.match.isSupportBfDg" src="~@/assets/icon/icon-012.png"> 比分
					</div>

					<div class="footBall_allOption_way_content">
						<div class="footBall_allOption_way_content_five">
							<div class="footBall_allOption_way_content_typeOther">主胜</div>
							<div v-if="dialogB.match.isSupportBf"
								class="footBall_allOption_way_content_flex5 footBall_allOption_way_content_flex5_typeSF">
								<template v-for="(itemB,indexB) in oCPData.bfZSMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.bfMatchResponse && dialogB.match.bfMatchResponse[itemB.select] }"
										@click="fSelectMoreOptions(itemB, 'bfMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.bfMatchResponse && dialogB.match.bfMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>
							<div v-else class="footBall_allOption_none app_h60">
								当前玩法不支持投注
							</div>
						</div>
						<div class="footBall_allOption_way_content_five">
							<div class="footBall_allOption_way_content_typeOther">平</div>
							<div v-if="dialogB.match.isSupportBf"
								class="footBall_allOption_way_content_flex5 footBall_allOption_way_content_flex5_typeP">
								<template v-for="(itemB,indexB) in oCPData.bfPMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.bfMatchResponse && dialogB.match.bfMatchResponse[itemB.select] }"
										@click="fSelectMoreOptions(itemB, 'bfMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.bfMatchResponse && dialogB.match.bfMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>
							<div v-else class="footBall_allOption_none app_h60">
								当前玩法不支持投注
							</div>
						</div>
						<div class="footBall_allOption_way_content_five">
							<div class="footBall_allOption_way_content_typeOther">主负</div>
							<div v-if="dialogB.match.isSupportBf"
								class="footBall_allOption_way_content_flex5 footBall_allOption_way_content_flex5_typeSF">
								<template v-for="(itemB,indexB) in oCPData.bfZFMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.bfMatchResponse && dialogB.match.bfMatchResponse[itemB.select] }"
										@click="fSelectMoreOptions(itemB, 'bfMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.bfMatchResponse && dialogB.match.bfMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>
							<div v-else class="footBall_allOption_none app_h60">
								当前玩法不支持投注
							</div>
						</div>
					</div>
				</div>

				<div class="footBall_allOption_way">
					<div class="footBall_allOption_way_title">
						<img v-if="dialogB.match.isSupportJqsDg" src="~@/assets/icon/icon-012.png"> 进球数
					</div>

					<div class="footBall_allOption_way_content">
						<div class="footBall_allOption_way_content_five">
							<div class="footBall_allOption_way_content_typeOther">进球数</div>

							<div v-if="dialogB.match.isSupportJqs" class="footBall_allOption_way_content_flex4">
								<template v-for="(itemB,indexB) in oCPData.jqsMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.jqsMatchResponse && dialogB.match.jqsMatchResponse[itemB.select] }"
										@click="fSelectMoreOptions(itemB, 'jqsMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.jqsMatchResponse && dialogB.match.jqsMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>

							<div v-else class="footBall_allOption_none app_h60">
								当前玩法不支持投注
							</div>
						</div>
					</div>
				</div>

				<div class="footBall_allOption_way">
					<div class="footBall_allOption_way_title">
						<img v-if="dialogB.match.isSupportBqcspfDg" src="~@/assets/icon/icon-012.png"> 半全场
					</div>

					<div class="footBall_allOption_way_content">
						<div class="footBall_allOption_way_content_five">
							<div class="footBall_allOption_way_content_typeOther">半全场</div>
							<div v-if="dialogB.match.isSupportBqcspf" class="footBall_allOption_way_content_flex3">
								<template v-for="(itemB,indexB) in oCPData.bqcspfMatchResponse" :key="indexB">
									<div :class="{'footBall_check': dialogB.match.bqcspfMatchResponse && dialogB.match.bqcspfMatchResponse[itemB.select] }"
										@click="fSelectMoreOptions(itemB, 'bqcspfMatchResponse')">
										<p>{{itemB.name}}</p>
										<p>
											{{dialogB.match.bqcspfMatchResponse && dialogB.match.bqcspfMatchResponse[itemB.key]}}
										</p>
									</div>
								</template>
							</div>
							<div v-else class="footBall_allOption_none app_h60">
								当前玩法不支持投注
							</div>
						</div>
					</div>
				</div>
			</div>
		</h-dialog-b>
		<h-dialog-c title="提示" dialogKey="dialogC" :show="dialogC.showDialog"
			:showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText"
			@fBeforeClose="fBeforeClose">
			<div class="footBall_tips">
				当前有未提交的购彩信息，如果执行返回您将自动放弃购彩信息！
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import HDialogB from "@/components/HDialog/HDialogB/index.vue";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import tipsContent from '@/utils/tipsContent';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnDeepClone,
		fnIsToday,
		fnObjForArraySort
	} from "@/utils/public.js";
	import {
		apiJCZQ
	} from '@/api/allplay.js';
	export default {
		name: "FootBall",
		components: {
			HDialogB,
			HDialogC
		},
		setup() {
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const fIsToday = fnIsToday;


			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (!dialogC.isSure) {
					// 白名单
					const WHITE_LIST = ['/bet', '/generalTxt'];
					// 确定返回
					if (WHITE_LIST.indexOf(to.path) === -1) {
						// 前往地址是否为投注地址--否
						if (aMatchSelect.value.length > 0) {
							// 赛事选项是否存在--是
							const timer = setTimeout(() => {
								dialogC.showDialog = true;
								clearTimeout(timer);
							}, 100)
							return false;
						}
					}
				} else {
					// 确定返回--是
					store.dispatch("allPlay/UFBSelected", []);
				}
			})


			// 当前页面数据
			const oCPData = reactive({
				// 让球胜平负
				spfMatchResponse: [{
						name: '胜',
						key: 'hostWinSp',
						select: 'hostWinSpSelect',
						sort: 0
					},
					{
						name: '平',
						key: 'drawSp',
						select: 'drawSpSelect',
						sort: 1
					},
					{
						name: '负',
						key: 'guestWinSp',
						select: 'guestWinSpSelect',
						sort: 2
					}
				],
				// 让球胜平负
				rqspfMatchResponse: [{
						name: '胜',
						key: 'hostRqWinSp',
						select: 'hostRqWinSpSelect',
						sort: 3
					},
					{
						name: '平',
						key: 'rqDrawSp',
						select: 'rqDrawSpSelect',
						sort: 4
					},
					{
						name: '负',
						key: 'guestRqWinSp',
						select: 'guestRqWinSpSelect',
						sort: 5
					}
				],
				// 比分主胜
				bfZSMatchResponse: [{
						name: '1:0',
						key: 's01s00',
						select: 's01s00Select',
						sort: 6
					},
					{
						name: '2:0',
						key: 's02s00',
						select: 's02s00Select',
						sort: 7
					},
					{
						name: '2:1',
						key: 's02s01',
						select: 's02s01Select',
						sort: 8
					},
					{
						name: '3:0',
						key: 's03s00',
						select: 's03s00Select',
						sort: 9
					},
					{
						name: '3:1',
						key: 's03s01',
						select: 's03s01Select',
						sort: 10
					},
					{
						name: '3:2',
						key: 's03s02',
						select: 's03s02Select',
						sort: 11
					},
					{
						name: '4:0',
						key: 's04s00',
						select: 's04s00Select',
						sort: 12
					},
					{
						name: '4:1',
						key: 's04s01',
						select: 's04s01Select',
						sort: 13
					},
					{
						name: '4:2',
						key: 's04s02',
						select: 's04s02Select',
						sort: 14
					},
					{
						name: '5:0',
						key: 's05s00',
						select: 's05s00Select',
						sort: 15
					},
					{
						name: '5:1',
						key: 's05s01',
						select: 's05s01Select',
						sort: 16
					},
					{
						name: '5:2',
						key: 's05s02',
						select: 's05s02Select',
						sort: 17
					},
					{
						name: '胜其他',
						key: 's1sh',
						select: 's1shSelect',
						sort: 18
					}
				],
				// 比分平
				bfPMatchResponse: [{
						name: '0:0',
						key: 's00s00',
						select: 's00s00Select',
						sort: 19
					},
					{
						name: '1:1',
						key: 's01s01',
						select: 's01s01Select',
						sort: 20
					},
					{
						name: '2:2',
						key: 's02s02',
						select: 's02s02Select',
						sort: 21
					},
					{
						name: '3:3',
						key: 's03s03',
						select: 's03s03Select',
						sort: 22
					},
					{
						name: '平其他',
						key: 's1sd',
						select: 's1sdSelect',
						sort: 23
					}
				],
				// 比分主负
				bfZFMatchResponse: [{
						name: '0:1',
						key: 's00s01',
						select: 's00s01Select',
						sort: 24
					},
					{
						name: '0:2',
						key: 's00s02',
						select: 's00s02Select',
						sort: 25
					},
					{
						name: '1:2',
						key: 's01s02',
						select: 's01s02Select',
						sort: 26
					},
					{
						name: '0:3',
						key: 's00s03',
						select: 's00s03Select',
						sort: 27
					},
					{
						name: '1:3',
						key: 's01s03',
						select: 's01s03Select',
						sort: 28
					},
					{
						name: '2:3',
						key: 's02s03',
						select: 's02s03Select',
						sort: 29
					},
					{
						name: '0:4',
						key: 's00s04',
						select: 's00s04Select',
						sort: 30
					},
					{
						name: '1:4',
						key: 's01s04',
						select: 's01s04Select',
						sort: 31
					},
					{
						name: '2:4',
						key: 's02s04',
						select: 's02s04Select',
						sort: 32
					},
					{
						name: '0:5',
						key: 's00s05',
						select: 's00s05Select',
						sort: 33
					},
					{
						name: '1:5',
						key: 's01s05',
						select: 's01s05Select',
						sort: 34
					},
					{
						name: '2:5',
						key: 's02s05',
						select: 's02s05Select',
						sort: 35
					},
					{
						name: '负其他',
						key: 's1sa',
						select: 's1saSelect',
						sort: 36
					}
				],
				// 进球数
				jqsMatchResponse: [{
						name: '0',
						key: 's0',
						select: 's0Select',
						sort: 37
					},
					{
						name: '1',
						key: 's1',
						select: 's1Select',
						sort: 38
					},
					{
						name: '2',
						key: 's2',
						select: 's2Select',
						sort: 39
					},
					{
						name: '3',
						key: 's3',
						select: 's3Select',
						sort: 40
					},
					{
						name: '4',
						key: 's4',
						select: 's4Select',
						sort: 41
					},
					{
						name: '5',
						key: 's5',
						select: 's5Select',
						sort: 42
					},
					{
						name: '6',
						key: 's6',
						select: 's6Select',
						sort: 43
					},
					{
						name: '7+',
						key: 's7',
						select: 's7Select',
						sort: 44
					}
				],
				// 半全场
				bqcspfMatchResponse: [{
						name: '胜-胜',
						key: 'hh',
						select: 'hhSelect',
						sort: 45
					},
					{
						name: '胜-平',
						key: 'hd',
						select: 'hdSelect',
						sort: 46
					},
					{
						name: '胜-负',
						key: 'ha',
						select: 'haSelect',
						sort: 47
					},
					{
						name: '平-胜',
						key: 'dh',
						select: 'dhSelect',
						sort: 48
					},
					{
						name: '平-平',
						key: 'dd',
						select: 'ddSelect',
						sort: 49
					},
					{
						name: '平-负',
						key: 'da',
						select: 'daSelect',
						sort: 50
					},
					{
						name: '负-胜',
						key: 'ah',
						select: 'ahSelect',
						sort: 51
					},
					{
						name: '负-平',
						key: 'ad',
						select: 'adSelect',
						sort: 52
					},
					{
						name: '负-负',
						key: 'aa',
						select: 'aaSelect',
						sort: 53
					}
				]
			});
			// 更多选项弹框
			const dialogB = reactive({
				title: {
					master: "主队",
					guest: "客队"
				},
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "确定",
				index: -1,
				indexA: -1,
				match: {}
			});
			// dialogC
			const dialogC = reactive({
				isSure: false, // 确定退出
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "继续返回"
			});

			// van-collapse的唯一值
			const nActiveNames = ref([]);
			// 是否禁用立即投注按钮
			const bIsDisabled = computed(() => {
				console.log(aMatchSelect)
				const tAMatchSelect = aMatchSelect.value;
				let tBIsDisabled = true

				if (tAMatchSelect.length > 1) {
					tBIsDisabled = false;
				} else if (tAMatchSelect.length === 1) {
					// 该数据选中非单关不存在，并且单关存在
					if (tAMatchSelect[0].selectNoDg <= 0 && tAMatchSelect[0].selectDg > 0) {
						tBIsDisabled = false;
					} else {
						tBIsDisabled = true;
					}
				} else {
					tBIsDisabled = true;
				}
				return tBIsDisabled
			});
			// 赛事列表
			const aMatch = ref([]);
			// 赛事列表-备份
			const aMatch_BackUp = ref([]);
			// 已选择赛事信息
			const aMatchSelect = computed(() => {
				return store.state.allPlay.FBSelected
			});
			// 全部赛事
			const fGetMatch = () => {
				Toast({
					type: "loading"
				});
				apiJCZQ({
					playType: 'JCZQ_HT'
				}).then(res => {
					console.log("请求全部赛事");
					let tData = res.result;
					console.log(tData);
					aMatch.value = fnDeepClone(tData);
					if (tData.length > 0) nActiveNames.value = [tData[0].issueNo];
					aMatch_BackUp.value = fnDeepClone(tData);

					fEcho();
				})
			};
			fGetMatch();

			// 数据回显
			const fEcho = () => {
				console.log("开始回显");
				let tAMatchSelect = aMatchSelect.value;
				const tAMatch = aMatch.value;
				let tCAMatchSelect = fnDeepClone(tAMatchSelect);
				if (tAMatchSelect.length === 0) return;
				console.log(tAMatchSelect)

				tCAMatchSelect.forEach((itemA, indexA) => {
					let tNoSame = false;
					tAMatch.forEach(itemB => {
						itemB.matchDetailResponseList.forEach((itemC, indexC) => {
							// 注意 注意 注意
							// 如果赛事胜平负新的数据是单关，但是回显数据并不是单关，那么则删除
							// 同理 如果赛事过关开关与回显数据不同，那么则删除
							if (itemA.id === itemC.id &&
								itemA.isSupportBf === itemC.isSupportBf &&
								itemA.isSupportBfDg === itemC.isSupportBfDg &&
								itemA.isSupportBqcspf === itemC.isSupportBqcspf &&
								itemA.isSupportBqcspfDg === itemC.isSupportBqcspfDg &&
								itemA.isSupportJqs === itemC.isSupportJqs &&
								itemA.isSupportJqsDg === itemC.isSupportJqsDg &&
								itemA.isSupportRqspfDg === itemC.isSupportRqspfDg &&
								itemA.isSupportRqspfGg === itemC.isSupportRqspfGg &&
								itemA.isSupportSpfDg === itemC.isSupportSpfDg &&
								itemA.isSupportSpfGg === itemC.isSupportSpfGg) {
								tNoSame = true;
								// 如果玩法的过关开启，则合并选项，如果未开启，则使用新数据
								itemA.spfMatchResponse = itemC.isSupportSpfGg ? {
									...itemA.spfMatchResponse,
									...itemC.spfMatchResponse
								} : itemC.spfMatchResponse;
								itemA.rqspfMatchResponse = itemC.isSupportRqspfGg ? {
									...itemA.rqspfMatchResponse,
									...itemC.rqspfMatchResponse
								} : itemC.rqspfMatchResponse;
								itemA.bfMatchResponse = itemC.isSupportBf ? {
									...itemA.bfMatchResponse,
									...itemC.bfMatchResponse
								} : itemC.bfMatchResponse;
								itemA.jqsMatchResponse = itemC.isSupportJqs ? {
									...itemA.jqsMatchResponse,
									...itemC.jqsMatchResponse
								} : itemC.jqsMatchResponse;
								itemA.bqcspfMatchResponse = itemC.isSupportBqcspf ? {
									...itemA.bqcspfMatchResponse,
									...itemC.bqcspfMatchResponse
								} : itemC.bqcspfMatchResponse;
								itemB.matchDetailResponseList[indexC] = fnDeepClone(itemA);
							}
						})
					})
					if (!tNoSame) delete tAMatchSelect[indexA];
				})

				// 处理后的数据
				let tHandleData = tAMatchSelect.filter(item => item !== undefined);

				tAMatchSelect = fnDeepClone(tHandleData);
				store.dispatch("allPlay/UFBSelected", fnDeepClone(tHandleData));
				console.log(aMatch.value)
			};
			/**
			 * [fSelectOptions 选择比赛选项]
			 * @param  {[Number]} Index [所属期次号下标]
			 * @param  {[Number]} IndexA [当前比赛下标]
			 * @param  {[Object]} Option [当前比赛选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @return {[Null]}
			 */
			const fSelectOptions = (Index, IndexA, Option, ObjName) => {
				const tMatchToday = aMatch.value[Index];
				const tMatch = tMatchToday["matchDetailResponseList"][IndexA];
				tMatch["issueNo"] = tMatchToday["issueNo"];
				fnHandleSelectData("all", tMatch, Option, ObjName);
			};


			/**
			 * [fnHandleSelectData 处理选中数据]
			 * @param  {[String]} IsDo [是否开始 从aMatchSelect中合并或者移除] 
			 * @param  {[Object]} Match [当前赛事信息]
			 * @param  {[Object]} Option [当前比赛选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @return {[Null]}
			 */
			const fnHandleSelectData = (IsDo, Match, Option, ObjName) => {
				console.log(IsDo, Match, Option, ObjName)
				if (IsDo === "all" || IsDo === "first") {
					if (aMatchSelect.value.length === 8) {
						// 当前赛事是否已添加到选项中
						let tAMatchSelect = aMatchSelect.value;
						// 是否存在
						let tIsHas = tAMatchSelect.filter(item => item.id === Match.id);
						if (tIsHas.length === 0 && !Match[ObjName][Option.select]) {
							return Toast({
								message: tipsContent.maxSession,
								position: 'bottom'
							});
						}
					}

					Match[ObjName][Option.select] = !Match[ObjName][Option.select];
					// 单关对应名字
					let dgName = false;
					// 设置单关对应字段
					switch (ObjName) {
						case "spfMatchResponse":
							dgName = "isSupportSpfDg";
							break;
						case "rqspfMatchResponse":
							dgName = "isSupportRqspfDg";
							break;
						case "bfMatchResponse":
							dgName = "isSupportBfDg";
							break;
						case "jqsMatchResponse":
							dgName = "isSupportJqsDg";
							break;
						case "bqcspfMatchResponse":
							dgName = "isSupportBqcspfDg";
							break;
						default:
							dgName = false;
							break;
					}

					// 计算已选中
					Match.selectNum = Match.selectNum ? Match.selectNum : 0;
					// 计算已选中单关
					Match.selectDg = Match.selectDg ? Match.selectDg : 0;
					// 计算已选中非单关：用于判断只有一场赛事时是否同时选中单关和非单关选项
					Match.selectNoDg = Match.selectNoDg ? Match.selectNoDg : 0;
					// 计算已选中更多
					Match.selectMore = Match.selectMore ? Match.selectMore : 0;

					// 当前赛事选中的选项为true
					if (Match[ObjName][Option.select]) {
						console.log(Match[ObjName])
						// 组装数据，方便投注页面使用
						let tObj = {
							name: Option.name,
							award: Match[ObjName][Option.key],
							key: Option.key,
							sort: Option.sort,
						};
						if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
							let tIsHasOption = Match[ObjName + "Select"].filter(item => item.key === tObj.key);
							if (tIsHasOption.length === 0) Match[ObjName + "Select"].push(tObj);
							fnObjForArraySort(Match[ObjName + "Select"], "sort");
						} else {
							Match[ObjName + "Select"] = [tObj];
						}


						Match.selectNum += 1;
						// 判断当前赛事选中的选项是否支持单关
						if (Match[dgName]) {
							Match.selectDg += 1;
						} else {
							Match.selectNoDg += 1;
						}
					} else {
						// 组装数据，方便投注页面使用
						let tObj = {
							name: Match[ObjName][Option.name],
							award: Match[ObjName][Option.key],
							key: Option.key,
							sort: Option.sort,
						};
						if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
							Match[ObjName + "Select"].forEach((item, index) => {
								if (item.key === tObj.key) {
									delete Match[ObjName + "Select"][index]
								}
							});
							Match[ObjName + "Select"] = fnDeepClone(Match[ObjName + "Select"].filter(item => item !==
								undefined));
						} else {
							Match[ObjName + "Select"] = [];
						}

						if (Match.selectNum > 0) Match.selectNum -= 1;
						// 判断当前赛事选中的选项是否支持单关
						if (Match[dgName]) {
							if (Match.selectDg > 0) Match.selectDg -= 1;
						} else {
							if (Match.selectNoDg > 0) Match.selectNoDg -= 1;
						}
					}

					// 判断当前选中选项是否为更多选项中独有的选项
					if (ObjName === 'bfMatchResponse' || ObjName === 'jqsMatchResponse' || ObjName ===
						'bqcspfMatchResponse') {
						if (Match[ObjName][Option.select]) {
							Match.selectMore += 1;
						} else {
							if (Match.selectMore > 0) Match.selectMore -= 1;
						}
					}
				}

				if (IsDo === "all" || IsDo === "second") {
					let tMatch = fnDeepClone(Match)
					// 当前赛事是否已添加到选项中
					let tAMatchSelect = aMatchSelect.value;
					// 是否已添加到选项中
					let tIsHas = false;
					for (let i = 0; i < tAMatchSelect.length; i++) {
						if (tAMatchSelect[i].id === Match.id) {
							// 当前赛事已选中数量大于0
							if (Match.selectNum > 0) {
								// 已添加则更新
								tIsHas = true;
								tAMatchSelect[i] = tMatch;
							} else {
								tAMatchSelect.splice(i, 1)
								break;
							}
						}
					}
					// 当前赛事已选中数量大于0 并且 未添加则添加
					if (Match.selectNum > 0 && !tIsHas) tAMatchSelect.push(tMatch);

					console.log(aMatchSelect);
				}

			}

			/**
			 * [fOpenMore 选择比赛选项]
			 * @param  {[Number]} Index [所属期次号下标]
			 * @param  {[Number]} IndexA [当前比赛下标]
			 * @param  {[Object]} Match [当前比赛信息]
			 * @return {[Null]}
			 */
			// 更多选项弹框--打开 (赛事)
			const fOpenMore = (Index, IndexA, Match) => {
				const tMatchToday = aMatch.value[Index];
				Match["issueNo"] = tMatchToday["issueNo"];

				console.log(Match);
				dialogB.title = {
					master: Match.hostShortName,
					guest: Match.guestShortName
				}
				dialogB.showDialog = true;
				dialogB.index = Index;
				dialogB.indexA = IndexA;
				dialogB.match = fnDeepClone(Match)
				console.log(dialogB);
			};
			/**
			 * [fSelectMoreOptions 选择比赛更多选项]
			 * @param  {[Object]} Option [当前选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @return {[Null]}
			 */
			const fSelectMoreOptions = (Option, ObjName) => {
				fnHandleSelectData("first", dialogB.match, Option, ObjName)
			};
			// 更多选项弹框--关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "dialogB") {
					if (action === "confirm") {
						console.log("确定选择");
						fnHandleSelectData("second", dialogB.match);
						aMatch.value[dialogB.index]["matchDetailResponseList"][dialogB.indexA] = fnDeepClone(dialogB
							.match);
						dialogB.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						console.log("关闭弹框");
						dialogB.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogC") {
					if (action === "confirm") {
						dialogC.showDialog = false;
						dialogC.isSure = true;
						fnIsHasHistory("/");
						fn(false)
					} else if (action === "cancel") {
						dialogC.showDialog = false;
						dialogC.isSure = false;
						fn(false)
					}
				}
			}

			// 清除所有
			const fCleanAll = () => {
				aMatch.value = fnDeepClone(aMatch_BackUp.value);
				aMatchSelect.value = [];
				store.dispatch("allPlay/UFBSelected", []);
			};
			// 立即投注
			const fBet = () => {
				if (bIsDisabled.value) return;
				store.dispatch("allPlay/UFBSelected", aMatchSelect.value);
				store.dispatch("allPlay/UOrderData", {});
				router.push({
					path: '/bet'
				})
			};

			// fLeft van-nav-bar 左侧点击事件
			const fLeft = () => {
				if (aMatchSelect.value.length > 0) {
					dialogC.showDialog = true;
				} else {
					fnIsHasHistory("/");
				}
			};
			// fRight van-nav-bar 右侧点击事件
			const fRight = () => {
				router.push({
					path: '/generalTxt',
					query: {
						agreement: 'playWayZQ'
					}
				})
			};
			return {
				nActiveNames,

				oCPData,
				bIsDisabled,
				dialogC,
				dialogB,
				aMatch,
				aMatchSelect,


				fSelectOptions,
				fOpenMore,
				fSelectMoreOptions,
				fBeforeClose,

				fCleanAll,
				fBet,
				fLeft,
				fRight,
				fIsToday,
			}
		}
	}
</script>

<style scoped lang="scss">
	.footBall {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		background-image: url('~@/assets/images/img_011.png');
		background-size: 100% auto;
		background-repeat: no-repeat;

		.footBall_none {
			margin: 0.2933rem 0.32rem 0;
			background: #FAFBFF;
			box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.footBall_body {
			padding: 0.2933rem 0.32rem 0;

			.footBall_day {
				background: #FAFBFF;
				box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;
			}
		}

		.footBall_bottom {
			padding: 0.2933rem 0.4rem;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.footBall_bottom_left {
				display: flex;
				align-items: center;

				&>img {
					width: 0.5333rem;
				}

				&>span {
					margin-left: 0.32rem;
					font-size: 0.32rem;
					color: #383838;

					.footBall_bottom_left_em1 {
						color: #E2335D
					}

					.footBall_bottom_left_em2 {
						color: #FF7671
					}
				}
			}

			.footBall_bottom_right {
				padding: 0.16rem 0.6133rem;
				background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
				border-radius: 0.4266rem;

				font-size: 0.3733rem;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 0.5333rem;
			}

			.footBall_bottom_right--disabled {
				background: #FFABA8;
			}
		}

		.footBall_tips {
			padding: 0.2666rem 0.64rem;
			margin-bottom: 0.5333rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}
	}
</style>
