<template>
	<div class="optimize app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="奖金优化" right-text="优化规则" left-arrow @click-left="fLeft" @click-right="fOpenRule" />
			</van-sticky>
		</div>

		<div class="optimize_body app_scroll app_flex">
			<div class="optimize_body_tabs">
				<van-tabs v-model:active="active" title-active-color="#383838" title-inactive-color="#979797"
					line-width="0.48rem" @click="fOnRendered" @disabled="fOnClickDisabled">
					<van-tab title="奖金平均" name="AVG_OPTIMIZE" />
					<van-tab title="风险最小" name="BORE_OPTIMIZE" :disabled="bDisabled_tab" />
					<van-tab title="奖金最高" name="BOLENG_OPTIMIZE" :disabled="bDisabled_tab" />
				</van-tabs>
			</div>
			<h-table-c :aContent="aList" :aLotteryType="oPlanDetail.lotteryTypeEnum" @fChangeMultiple="fChangeMultiple"></h-table-c>
		</div>
		<div class="optimize_foot">
			<div class="optimize_foot_b">
				理论奖金<em>{{calculate_prePrizeAmountMin && calculate_prePrizeAmountMin !== calculate_prePrizeAmount ? fnKeepFigures(calculate_prePrizeAmountMin) + "-" + fnKeepFigures(calculate_prePrizeAmount) : fnKeepFigures(calculate_prePrizeAmount)}}</em>
			</div>
			<div class="optimize_foot_e">
				发布跟单后最低跟单金额 {{oPlanDetail.calculate.followAmountMin}} 元
			</div>
			<div class="optimize_foot_a">
				<div class="optimize_foot_a_a">
					投注
					<van-stepper class="optimize_foot_c--color" v-model="oPlanDetail.calculate.totalFee" step="2"
						integer :disable-minus="bDisable_minus" :disable-plus="bDisable_plus" input-width="1.92rem" @focus="fFocus" @blur="fBlur" @plus="fModify('plus')" @minus="fModify('minus')" @overlimit="fOverlimit" /> 元
				</div>
				<div class="optimize_foot_a_b">
					<span class="optimize_foot_a_b_a" @click="fReleaseOrder">发布跟单</span>
					<span class="optimize_foot_a_b_b" @click="fCreateOrder">彩店出票</span>
				</div>
			</div>
		</div>


		<h-dialog-a title="保障佣金说明" dialogKey="dialogA" :show="oDialogAll.dialogA.showDialog"
			:showCancelButton="oDialogAll.dialogA.showCancelButton"
			:showConfirmButton="oDialogAll.dialogA.showConfirmButton"
			:confirmButtonText="oDialogAll.dialogA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips_a">
				<p>1.保障佣金是为了保障平台用户在跟单下单的时候赔率变化太大而损失利益。</p>
				<p>2.跟单者的中奖金额&#60;投注金额*保障佣金倍数时，不支付佣金。</p>
				<p>3.发单人可在平台规定比例内设置保障佣金倍数时，不设置默认最低保障佣金倍数。</p>
			</div>
		</h-dialog-a>
		<h-dialog-c title="提示" dialogKey="dialogCA" :show="oDialogAll.dialogCA.showDialog"
			:showCancelButton="oDialogAll.dialogCA.showCancelButton"
			:confirmButtonText="oDialogAll.dialogCA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				您已绑定{{oPlanDetail.shopName}}，支付成功后在该店出票。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCB" :show="oDialogAll.dialogCB.showDialog"
			:showCancelButton="oDialogAll.dialogCB.showCancelButton"
			:confirmButtonText="oDialogAll.dialogCB.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				当前投注盘口变化,是否继续完成支付?
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCC" :show="oDialogAll.dialogCC.showDialog"
			:showCancelButton="oDialogAll.dialogCC.showCancelButton"
			:confirmButtonText="oDialogAll.dialogCC.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				您有跟单方案未支付佣金，请先支付完佣金后再投注。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCD" :show="oDialogAll.dialogCD.showDialog"
			:showCancelButton="oDialogAll.dialogCD.showCancelButton"
			:confirmButtonText="oDialogAll.dialogCD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips bet_dialogCD">
				您确定要放弃支付吗？
			</div>
		</h-dialog-c>
		<h-actionSheet-a :title="oPayRelated.title" :show="oPayRelated.showDialog" :payDetail="oPayRelated.payDetail"
			:oButton="oPayRelated.oButton" sRechargeRedirect="plan" @fBeforeClose="fBeforeCloseActionSheetA" @fSubmit="fSubmitActionSheetA">
		</h-actionSheet-a>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onActivated,
		inject,
		computed
	} from "vue";
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnKeepFigures,
		fnFloatMultiply
	} from "@/utils/public.js";
	import {
		apiPlanLotteryShop,
		apiPlanOptimize,
		apiPlanCreate,
		apiOrderPay,
		apiPlanTheoryBonus
	} from '@/api/allplay.js';

	import HActionSheetA from "../../components/HActionSheet/HActionSheetA";
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import HTableC from "@/components/HTable/tableC.vue";
	export default {
		name: "Optimize",
		components: {
			HActionSheetA,
			HDialogA,
			HDialogC,
			HTableC
		},
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const fGlobalReload = inject("fGlobalReload");
			const OrderData = store.state.allPlay.orderData;
			const UserInfo = store.state.userInfo.userInfo;


			// 当前tab的name
			const active = ref("AVG_OPTIMIZE");
			// 上次tab的name
			const active_old = ref("AVG_OPTIMIZE");
			// tab是否被禁用
			const bDisabled_tab = ref(false);
			// table是否禁用change事件
			const bDisabled_tableC = ref(false);
			// 是否禁用减少按钮
			const bDisable_minus = computed(() => {
				return oPlanDetail.calculate.totalFee - 2 < oPlanDetail.calculate.minTotalFee_local
			});
			// 是否禁用增加按钮
			const bDisable_plus = computed(() => {
				return oPlanDetail.calculate.totalFee + 2 < oPlanDetail.calculate.minTotalFee_local
			});
			// 投注金额
			const s_old_totalFee = ref(0);

			// 方案详情
			const oPlanDetail = reactive({
				shopName: "", // 绑定彩票店名称
				lotteryTypeEnum: "JCZQ",
				calculate: {
					multiple: 1, // 下注倍数
					minBetMultiple: 1, // 最低下注倍数
					minTotalFee_local: 0, // 本地最低下注金额
					minTotalFee: 0, // 最低下注金额
					followAmountMin: 0, //最低跟单金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					totalFee: 0, // 方案投注金额
				}
			});

			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				console.log(route_meta)
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
					fFirst();
				}
			});

			// 理论最高奖金
			const calculate_prePrizeAmount = ref(0);
			// 理论最低奖金
			const calculate_prePrizeAmountMin = ref(0);
			// tabs切换
			const fOnRendered = (Name) => {
				console.log(Name);
				// 如果当前点击的tab和上次的tab的name值一样，则无变化
				if (Name === active_old.value) {
					return;
				} else {
					active_old.value = Name;
				}
				let t_totalFee = oPlanDetail.calculate.totalFee;// 当前金额
				let t_totalFee_prime = OrderData.totalFee;// 最初金额
				let t_minTotalFee_local = oPlanDetail.calculate.minTotalFee_local;// 当前页面最低下注金额
				if (t_totalFee >= t_minTotalFee_local) {
					oPlanDetail.calculate.totalFee = t_totalFee;
				} else if (t_totalFee < t_totalFee_prime) {
					oPlanDetail.calculate.totalFee = t_totalFee_prime
				}
				fPlanOptimize(Name)
			};
			// 禁用标签
			const fOnClickDisabled = (name, title) => {
				console.log(name);
				Toast({
					message: "此玩法不支持"+ title + "优化方式",
					position: 'bottom'
				});
			};
			// 数组
			const aList = ref([]);
			// 页面数据
			const oDialogAll = reactive({
				dialogA: {
					showDialog: false,
					showCancelButton: false,
					showConfirmButton: false
				},
				dialogCA: {
					showDialog: false,
					showCancelButton: true,
					confirmButtonText: "确定"
				},
				dialogCB: {
					showDialog: false,
					showCancelButton: false,
					confirmButtonText: "确定"
				},
				dialogCC: {
					showDialog: false,
					showCancelButton: false,
					confirmButtonText: "去支付佣金"
				},
				dialogCD: {
					showDialog: false,
					showCancelButton: true,
					confirmButtonText: "确定"
				}
			});
			// 支付相关信息
			const oPayRelated = reactive({
				payDetail: {
					availableAmount: 0, // 店内余额
					totalFee: 0, // 方案金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					planId: null, // 方案ID
					isEnough: false // 是否可以支付
				},
				oButton: {
					isShow: true,
					text: "确定"
				},
				showDialog: false, //是否展示弹框
				title: "彩店出票"
			});


			// 方案优化
			// pType: 奖金优化方式
			// 奖金优化方式枚举OptimizeTypeEnum值：
			// NOT_OPTIMIZING(0, "不优化"),
			// AVG_OPTIMIZE(1, "平均优化【奖金平均】"),
			// BOLENG_OPTIMIZE(2, "博冷优化【风险最小】"),
			// BORE_OPTIMIZE(3, "博热优化【奖金最高】");
			const fPlanOptimize = (pType) => {
				let t_mixTypes = "过关方式";
				let t_length = OrderData.itemList.length;
				if (!OrderData.itemList || t_length === 0) {
					return;
				} else if (t_length === 1) {
					t_mixTypes = "单关";
				} else {
					t_mixTypes = t_length + "串1";
				}
				if (OrderData.mixTypes === t_mixTypes) {
					bDisabled_tab.value = false
				} else {
					bDisabled_tab.value = true
				}
				Toast({
					type: "loading"
				});
				apiPlanOptimize({
					optimizeType: pType,
					itemList: OrderData.itemList,
					mixTypes: OrderData.mixTypes,
					lotteryTypeEnum: OrderData.lotteryTypeEnum,
					betMoney: oPlanDetail.calculate.totalFee
				}).then(res => {
					console.log(res);
					let tData = res && res.result ? res.result : {};
					let itemList = tData.itemList;
					itemList.forEach(item => {
						item.isOpen = false;
						item.isDisable = false;
					});
					oPlanDetail.lotteryTypeEnum = OrderData.lotteryTypeEnum;
					oPlanDetail.calculate.prePrizeAmount = tData.prePrizeAmount;
					oPlanDetail.calculate.prePrizeAmountMin = tData.prePrizeAmountMin;
					oPlanDetail.calculate.followAmountMin = tData.followAmountMin;
					oPlanDetail.calculate.minTotalFee_local = itemList.length * 2;
					oPlanDetail.calculate.minTotalFee = OrderData.minTotalFee;
					aList.value = itemList;

					return Promise.resolve();
				}).then(() => {
					fPlanTheoryBonus()
				})
			};
			// 理论奖金计算
			const fPlanTheoryBonus = () => {
				Toast({
					type: "loading"
				});
				apiPlanTheoryBonus({
					itemList: fHandleOptimizeData()
				}).then(res => {
					let tData = res && res.result ? res.result : {
						prePrizeAmount: 0,
						prePrizeAmountMin: 0,
						followAmountMin: 0
					};
					calculate_prePrizeAmount.value = tData.prePrizeAmount ? tData.prePrizeAmount : calculate_prePrizeAmount.value;
					calculate_prePrizeAmountMin.value = tData.prePrizeAmountMin ? tData.prePrizeAmountMin : calculate_prePrizeAmountMin.value;
					oPlanDetail.calculate.followAmountMin = tData.followAmountMin ? tData.followAmountMin : oPlanDetail.calculate.followAmountMin;
				})
			};

			// 首次
			const fFirst = () => {
				console.log(OrderData);
				oPlanDetail.calculate.totalFee = OrderData.totalFee;
				fPlanOptimize("AVG_OPTIMIZE")
			};
			// 赛事变动
			const fChangeMultiple = () => {
				setTimeout(() => {
					if (bDisabled_tableC.value) {
						return setTimeout(() => {
							bDisabled_tableC.value = false;
						}, 0)
					}
					// 倍数
					let multiple = 0;
					aList.value.forEach(item => {
						multiple += item.multiple
					})
					// 投注金额
					oPlanDetail.calculate.totalFee = fnFloatMultiply(multiple, 2);
					fPlanTheoryBonus();
				}, 0)
			};
			// 投注金额输入框聚焦
			const fFocus = () => {
				s_old_totalFee.value = oPlanDetail.calculate.totalFee
			};
			// 投注金额输入框失焦
			const fBlur = () => {

				if (oPlanDetail.calculate.totalFee < oPlanDetail.calculate.minTotalFee_local) {
					oPlanDetail.calculate.totalFee = s_old_totalFee.value;
					return Toast({
						message: "奖金优化所需最低投注" + oPlanDetail.calculate.minTotalFee_local + "元",
						position: 'bottom'
					});
				}
				bDisabled_tableC.value = true;
				if (oPlanDetail.calculate.totalFee % 2 !== 0) {
					oPlanDetail.calculate.totalFee += 1;
					Toast({
						message: "投注金额必须是偶数",
						position: 'bottom'
					});
				}
				fPlanOptimize(active.value);
			};
			/**
			 * 投注金额点击不可用的按钮时触发
			 * @param {type} 点击增加/减少按钮类型：plus/minus
			 * */
			const fOverlimit = () => {
				Toast({
					message: "奖金优化所需最低投注" + oPlanDetail.calculate.minTotalFee_local + "元",
					position: 'bottom'
				});
			};
			// 投注金额输入框按钮加减
			const fModify = () => {
				bDisabled_tableC.value = true;
				setTimeout(() => {
					Toast({
						type: "loading"
					});
					fPlanOptimize(active.value);
				}, 0)
			};

			// 发布跟单
			const fReleaseOrder = () => {
				if (!OrderData.itemList) return Toast({
					message: "当前选择的赛事较少不支持下单",
					position: 'bottom'
				});
				const tMinTotalFee = oPlanDetail.calculate.minTotalFee;
				console.log(oPlanDetail.calculate.totalFee)
				if (tMinTotalFee && (tMinTotalFee > oPlanDetail.calculate.totalFee)) {
					return Toast({
						message: "投注金额最低" + tMinTotalFee + "元",
						position: 'bottom'
					});
				} else {
					apiPlanOptimize({
						optimizeType: active.value,
						itemList: OrderData.itemList,
						mixTypes: OrderData.mixTypes,
						lotteryTypeEnum: OrderData.lotteryTypeEnum,
						betMoney: oPlanDetail.calculate.totalFee
					}).then(res => {
						if(res.success){
							// 创建订单数据，并存储到vuex中
							let t_oOrderData = {
								optimizeItemList: fHandleOptimizeData(),
								optimizeType: active.value,
								...OrderData,
								totalFee: oPlanDetail.calculate.totalFee
							};
							store.dispatch("allPlay/UOrderData", t_oOrderData);
							router.push({
								path: '/release'
							});
						}})

				}
			};

			// 创建方案
			const fCreateOrder = () => {
				if (!OrderData.itemList) return Toast({
					message: "当前选择的赛事较少不支持下单",
					position: 'bottom'
				});

				const tMinTotalFee = oPlanDetail.calculate.minTotalFee;
				if (tMinTotalFee && (tMinTotalFee > oPlanDetail.calculate.totalFee)) {
					return Toast({
						message: "投注金额最低" + tMinTotalFee + "元",
						position: 'bottom'
					});
				} else {
					apiPlanOptimize({
						optimizeType: active.value,
						itemList: OrderData.itemList,
						mixTypes: OrderData.mixTypes,
						lotteryTypeEnum: OrderData.lotteryTypeEnum,
						betMoney: oPlanDetail.calculate.totalFee
					}).then(res => {
						if(res.success){
							Toast({
								type: "loading"
							});
							apiPlanLotteryShop({
								roleId: UserInfo.roleId,
								userId: UserInfo.userId,
								lotteryTypeEnum: OrderData.lotteryTypeEnum
							}).then(res => {
								let tData = res && res.result ? res.result : {};
								let tShopName = "";
								if (tData.canBet) {
									for (let i = 0, j = tData.lotteryShopList.length; i < j; i++) {
										if (i === 0) {
											tShopName = "" + tData.lotteryShopList[i].shopName
										} else {
											tShopName = tShopName + "/" + tData.lotteryShopList[i].shopName
										}
									}
									oPlanDetail.shopName = tShopName;
									oDialogAll.dialogCA.showDialog = true;
								}
							});
						}
					})
				}
			};

			// 数据处理-奖金优化
			const fHandleOptimizeData = () => {
				let tArr = [];
				aList.value.forEach(item => {
					if (item.isDisable) return;
					let t_aGroupList = [];
					item.groupList.forEach(itemA => {
						let objA = {
							matchId: itemA.matchId,
							optionEnum: itemA.optionEnum,
							playTypeEnum: itemA.playTypeEnum,
							realOdds: itemA.realOdds
						};
						t_aGroupList.push(objA)
					})

					item.amount = fnFloatMultiply(item.multiple, item.singleAmount);
					let obj = {
						amount: item.amount,
						mixTypes: item.mixTypes,
						multiple: item.multiple,
						groupList: t_aGroupList
					};
					tArr.push(obj);
				})
				return tArr;
			};
			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						// 跳转至跟单页面
						console.log("跳转至跟单页面");
						oDialogAll.dialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						// 关闭弹框
						console.log("关闭弹框");
						oDialogAll.dialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCA") {
					if (action === "confirm") {

						Toast({
							type: "loading"
						});
						apiPlanCreate({
							optimizeItemList: fHandleOptimizeData(),
							optimizeType: active.value,
							canCopy: 0,
							itemList: OrderData.itemList,
							lotterySubTypeEnum: OrderData.lotterySubTypeEnum,
							lotteryTypeEnum: OrderData.lotteryTypeEnum,
							mixTypes: OrderData.mixTypes,
							multiple: OrderData.multiple,
							profitProtectRate: 0,
							roleId: UserInfo.roleId,
							roleName: UserInfo.roleName,
							shopId: "1",
							sourceId: "",
							totalFee: oPlanDetail.calculate.totalFee,
							userId: UserInfo.userId,
							userName: UserInfo.realName
						}).then(res => {
							let tData = res.result ? res.result : {};
							oPayRelated.payDetail.availableAmount = tData.availableAmount ? tData
								.availableAmount : 0;
							oPayRelated.payDetail.totalFee = tData.totalFee ? tData.totalFee : 0;
							oPayRelated.payDetail.prePrizeAmount = tData.prePrizeAmount ? tData
								.prePrizeAmount : 0;
							oPayRelated.payDetail.prePrizeAmountMin = tData.prePrizeAmountMin ? tData
								.prePrizeAmountMin : 0;
							oPayRelated.payDetail.planId = tData.planId;
							oPayRelated.payDetail.isEnough = tData.isEnough;
							oDialogAll.dialogCA.showDialog = false;
							store.dispatch("allPlay/UFBSelected", []);
							store.dispatch("allPlay/UBBSelected", []);
							oPayRelated.showDialog = true;
							fn(false)
						})
					} else if (action === "cancel") {
						oDialogAll.dialogCA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCC") {
					if (action === "confirm") {
						oDialogAll.dialogCC.showDialog = false;
						fn(false);
						// 跳转支付佣金界面
						router.replace({
							path: '/recordGD',
							query: {
								redirect: 'mine'
							}
						})
					} else if (action === "cancel") {
						oDialogAll.dialogCC.showDialog = false;
						fn(false);
					}
				} else if (dialogKey === "dialogCD") {
					if (action === "confirm") {
						oPayRelated.showDialog = false;
						oDialogAll.dialogCD.showDialog = false;
						fn(false);
						// 跳转方案列表页
						router.replace({
							path: '/plan'
						})
					} else if (action === "cancel") {
						oDialogAll.dialogCD.showDialog = false;
						fn(false);
					}
				}
			};
			/* fBeforeCloseActionSheetA ActionSheetA关闭之前
			 * action 操作的按钮（confirm：确定；cancel：取消；overlay：点击遮罩层）
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fBeforeCloseActionSheetA = (action, fn) => {
				if (action === 'confirm') {
					oPayRelated.showDialog = false;
					fn(false)
				} else if (action === 'cancel') {
					oDialogAll.dialogCD.showDialog = true;
					fn(true)
				}
			};
			/* fSubmitActionSheetA 支付的动作面板中的支付按钮
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fSubmitActionSheetA = (fn) => {
				// 该订单是否可支付
				if (!oPayRelated.payDetail.isEnough) return;
				Toast({
					type: "loading"
				});
				// 订单支付
				apiOrderPay({
					balance: oPayRelated.payDetail.totalFee,
					otherFee: 0,
					payType: 1,
					planId: oPayRelated.payDetail.planId,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oPayRelated.showDialog = false;
					fn(false);
					let tData = res.result;
					// 是否支付成功
					if (tData["payResult"]) {
						// 成功
						Toast({
							message: tData.message ? tData.message : "支付成功",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						});
					} else if (tData["orderUnPayReasonEnum"] === "HAVE_UN_PAY_FEE") {
						// 失败:需要支付佣金--打开跳转到佣金弹框
						oDialogAll.dialogCC.showDialog = true;
					} else {
						Toast({
							message: tData.message ? tData.message : "支付失败",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						})
					}
				})
			};

			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			const fOpenRule = () => {
				let t_oOrderData = {
					optimizeItemList: fHandleOptimizeData(),
					optimizeType: active.value,
					...OrderData,
					totalFee: oPlanDetail.calculate.totalFee
				};
				store.dispatch("allPlay/UOrderData", t_oOrderData);
				router.push({
					path: '/generalTxt',
					query: {
						agreement: 'optimize'
					}
				})
			};



			return {
				aList,
				oPlanDetail,
				bDisabled_tab,
				calculate_prePrizeAmount,
				calculate_prePrizeAmountMin,
				active,
				oDialogAll,
				fOnRendered,
				fOnClickDisabled,
				fChangeMultiple,
				fModify,
				fFocus,
				fBlur,
				fOverlimit,
				fReleaseOrder,
				fCreateOrder,

				bDisable_minus,
				bDisable_plus,

				oPayRelated,
				fBeforeCloseActionSheetA,
				fSubmitActionSheetA,
				fBeforeClose,
				fLeft,
				fOpenRule,

				fnKeepFigures
			}
		}
	}
</script>

<style scoped lang="scss">
	.optimize {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.optimize_body {
			margin: 0.32rem;
			background: #FAFBFF;
			box-shadow: 0 0.0533rem 0.4266rem 0 rgba(224, 228, 246, 0.5);
			border-radius: 0.32rem;

			.optimize_body_tabs {
				border-radius: inherit;
				overflow: hidden;
			}


		}
	}

	.optimize_foot {
		position: relative;
		padding: 0 0.4rem 0;
		background: #FFFFFF;
		border-radius: 0.2133rem 0.4266rem 0;

		.optimize_foot_a {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.optimize_foot_a_a {
				padding: 0.24rem 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;

				&>em {
					margin-left: 0.1066rem;
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
				}
			}

			.optimize_foot_a_b {
				margin-left: 0.2666rem;
				flex: 1;
				padding: 0.16rem 0;
				display: flex;
				align-items: center;
				justify-content: center;

				&>span {
					flex: 1;
					box-sizing: border-box;
					display: inline-block;
					text-align: center;
					height: 1.0133rem;
					line-height: 1.0133rem;
				}

				.optimize_foot_a_b_a {
					background: #FFFFFF;
					border-radius: 0.6666rem 0px 0px 0.6666rem;
					border: 0.0266rem solid #e2335d;

					font-size: 0.3733rem;
					font-weight: 500;
					color: #E2335D;
				}

				.optimize_foot_a_b_b {
					background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
					border-radius: 0 0.6666rem 0.6666rem 0;

					font-size: 0.3733rem;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
		}

		.optimize_foot_b {
			padding: 0.32rem 0;
			display: flex;
			align-items: center;
			justify-content: center;

			font-size: 0.3733rem;
			font-weight: 400;
			color: #979797;

			&>em {
				margin-left: 0.1066rem;
				font-size: 0.48rem;
				font-weight: bold;
				color: #E2335D;
			}
		}

		.optimize_foot_e {
			padding: 0.3466rem 0 0.2933rem;
			text-align: center;
			font-size: 0.3733rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5333rem;
		}
	}

	.optimize_foot::after {
		content: "";
		position: absolute;
		top: -0.5866rem;
		left: 0;
		width: 100%;
		height: 0.5866rem;
		background: linear-gradient(180deg, rgba(241, 242, 247, 0) 0%, #E6E7EA 100%);
	}

	.bet_tips {
		padding: 0.2666rem 0.64rem 0.88rem;
		font-size: 0.4266rem;
		font-weight: 400;
		color: #383838;
		line-height: 0.5866rem;
	}

	.bet_tips_a {
		padding: 0.2666rem 0.64rem 0.8rem;

		&>p {
			margin-top: 0.8rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}
	}

	.bet_dialogCD {
		text-align: center;
	}
</style>
