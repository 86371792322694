<template>
	<div class="bet app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="投注" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div v-if="aMatchSelect[PLAYWAY].length > 0" class="bet_body app_scroll">
			<div class="bet_body_match" v-for="(item,index) in aMatchSelect[PLAYWAY]" :key="index">
				<div class="bet_body_match_title">
					<span>{{item.hostShortName}}</span>
					<img class="bet_body_match_title_b" src="~@/assets/icon/icon-011.png">
					<span>{{item.guestShortName}}</span>

					<img class="bet_body_match_title_d" src="~@/assets/icon/icon-016.png" @click="fCloseMatch(index)">
				</div>
				<div class="bet_body_match_content">
					<div class="bet_body_match_content_a">
						<div v-for="(itemA) in item[PLAYWAY + 'Select']" :key="itemA.key"
							class="bet_body_match_content_aa">
							<p>{{itemA.name}}</p>
							<p>{{itemA.award}}</p>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div v-else class="bet_body app_scroll">
			<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')" description="请选择投注赛事" />
		</div>
		<div class="bet_foot">
			<div class="bet_foot_a">
				<span class="bet_foot_a_style">过关方式：</span>
				<div class="bet_foot_a_content">
					<em v-if="OPTIONS_PLAYWAY[PLAYWAY].minStrand === 1"
						:class="{'bet_foot_a_content_select' : aStrandSelect.indexOf(1) !==-1}"
						@click="fSelectStrand(1)">单关</em>
					<template v-for="item in (aMatchSelect[PLAYWAY].length < OPTIONS_PLAYWAY[PLAYWAY].maxStrand ? aMatchSelect[PLAYWAY].length : OPTIONS_PLAYWAY[PLAYWAY].maxStrand)" :key="item">
						<em v-if="OPTIONS_PLAYWAY[PLAYWAY].minStrand !== 1 ? OPTIONS_PLAYWAY[PLAYWAY].minStrand <= item : OPTIONS_PLAYWAY[PLAYWAY].minStrand < item"
							:class="{'bet_foot_a_content_select' : aStrandSelect.indexOf(item) !== -1}"
							@click="fSelectStrand(item)">
							{{item}}串1
						</em>
					</template>
				</div>
			</div>
			<div class="bet_foot_b">
				理论奖金
				<em>{{calculate_prePrizeAmountMin && calculate_prePrizeAmountMin !== calculate_prePrizeAmount ? fnKeepFigures(calculate_prePrizeAmountMin) + " - " + fnKeepFigures(calculate_prePrizeAmount) : fnKeepFigures(calculate_prePrizeAmount)}}</em>
				元
			</div>
			<div class="bet_foot_c">
				即时赔率仅供参考，实际奖金计算以开奖赔率为准
			</div>
			<div class="bet_foot_d">
				<van-stepper v-model="oPlanDetail.calculate.multiple" integer
					:min="oPlanDetail.calculate.minBetMultiple" max="100000" input-width="1.6533rem" />
				<div class="bet_foot_d_a">
					<!-- :class="{'bet_foot_d_a--disabled' : oPlanDetail.isDisableCreate}" -->
					<!-- <span class="bet_foot_d_a_a" @click="fOperationOrder('release')">发布跟单</span> -->
					<span class="bet_foot_d_a_a">￥{{calculate_totalFee}}</span>
					<span class="bet_foot_d_a_b" @click="fCreateOrder">彩店出票</span>
				</div>
			</div>
		</div>


		<h-dialog-a title="保障佣金说明" dialogKey="dialogA" :show="oCPData.oYCData.dialogA.showDialog"
			:showCancelButton="oCPData.oYCData.dialogA.showCancelButton"
			:showConfirmButton="oCPData.oYCData.dialogA.showConfirmButton"
			:confirmButtonText="oCPData.oYCData.dialogA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips_a">
				<p>1.保障佣金是为了保障平台用户在跟单下单的时候赔率变化太大而损失利益。</p>
				<p>2.跟单者的中奖金额&#60;投注金额*保障佣金倍数时，不支付佣金。</p>
				<p>3.发单人可在平台规定比例内设置保障佣金倍数时，不设置默认最低保障佣金倍数。</p>
			</div>
		</h-dialog-a>
		<h-dialog-c title="提示" dialogKey="dialogCA" :show="oCPData.oYCData.dialogCA.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCA.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				您已绑定{{oPlanDetail.shopName}}，支付成功后在该店出票。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCB" :show="oCPData.oYCData.dialogCB.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCB.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCB.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				当前投注盘口变化,是否继续完成支付?
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCC" :show="oCPData.oYCData.dialogCC.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCC.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCC.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips">
				您有跟单方案未支付佣金，请先支付完佣金后再投注。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCD" :show="oCPData.oYCData.dialogCD.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="bet_tips bet_dialogCD">
				您确定要放弃支付吗？
			</div>
		</h-dialog-c>
		<h-actionSheet-a :title="oPayRelated.title" :show="oPayRelated.showDialog" :payDetail="oPayRelated.payDetail"
			:oButton="oPayRelated.oButton" sRechargeRedirect="plan" @fBeforeClose="fBeforeCloseActionSheetA" @fSubmit="fSubmitActionSheetA">
		</h-actionSheet-a>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import HActionSheetA from "../../components/HActionSheet/HActionSheetA";
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import {
		fnIsHasHistory
	} from "@/utils/router.js"
	import {
		fnDeepClone,
		fnKeepFigures
	} from "@/utils/public.js";
	import {
		bjdcOptions as OPTIONS,
		bjdcPlayWay as OPTIONS_PLAYWAY
	} from "@/utils/options.js";
	import {
		apiPlanCalculate,
		apiPlanLotteryShop,
		apiPlanCreate,
		apiOrderPay
	} from '@/api/allplay.js';
	export default {
		name: "BetBD",
		components: {
			HActionSheetA,
			HDialogA,
			HDialogC
		},
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const route_query = route.query;
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;
			
			const PLAYWAY = route_query.playWay; //玩法
			const LOTTERYSUBTYPEENUM = OPTIONS_PLAYWAY[PLAYWAY]["code"]; // 玩法对应的code
			// sfgg,spf,dcbf,jqs,bqcspf

			// 页面数据
			const oCPData = reactive({
				oNCData: {},
				oYCData: {
					dialogA: {
						showDialog: false,
						showCancelButton: false,
						showConfirmButton: false
					},
					dialogCA: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCB: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
					dialogCC: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "去支付佣金"
					},
					dialogCD: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
				}
			});

			// 方案详情
			const oPlanDetail = reactive({
				isDisableCreate: true, // 是否可以彩店出票
				shopName: "", // 绑定彩票店名称
				calculate: {
					multiple: 1, // 下注倍数
					minBetMultiple: 1, // 最低下注倍数
					minTotalFee: null, // 最低下注金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					totalFee: 0, // 方案投注金额
				}
			});
			// 理论最高奖金
			const calculate_prePrizeAmount = computed(() => {
				return oPlanDetail.calculate.prePrizeAmount * 100 * oPlanDetail.calculate.multiple / 100
			});
			// 理论最低奖金
			const calculate_prePrizeAmountMin = computed(() => {
				return oPlanDetail.calculate.prePrizeAmountMin * 100 * oPlanDetail.calculate.multiple / 100
			});
			// 方案投注金额
			const calculate_totalFee = computed(() => {
				return oPlanDetail.calculate.totalFee * 100 * oPlanDetail.calculate.multiple / 100
			});


			// 已选择赛事信息
			const aMatchSelect = ref(fnDeepClone(store.state.allPlay.BJDCSelected));
			console.log(aMatchSelect);
			// 流量APP选择赛事
			const aBetSelect = ref(fnDeepClone(store.state.allPlay.BETSelected));
			//	已选择过关方式
			const aStrandSelect = ref([]);

			// 最终合并数据
			const aMergeData = ref([]);

			// 如果平台赛事存在则反向解析
			const fReverse = () => {
				store.dispatch("allPlay/UBETSelected", {});
				if (aBetSelect.value && aBetSelect.value.itemList) {
					let tBet = aBetSelect.value.itemList;
					let tArr = [];
					for (let i = 0, j = tBet.length; i < j; i++) {
						let tObj = {
							gameNo: tBet[i].gameNo,
							guestShortName: tBet[i].guestShortName,
							hostShortName: tBet[i].hostShortName,
							issueNo: tBet[i].issueNo,
							id: tBet[i].matchId,
							rqspfPlate: tBet[i].rqspfPlate
						};
						let tOptions = tBet[i].itemDetailList;

						for (let n = 0, m = tOptions.length; n < m; n++) {
							for (let Key in OPTIONS) {
								//只遍历对象自身的属性，而不包含继承于原型链上的属性。  
								if (Object.prototype.hasOwnProperty.call(OPTIONS, Key) === true) {
									if (OPTIONS[Key].lotteryResultEnum === tOptions[n].lotteryResultEnum &&
										OPTIONS[Key].lotterySubTypeEnum === tOptions[n].lotterySubTypeEnum) {
										let tSelectType = "";
										switch (OPTIONS[Key].lotterySubTypeEnum) {
											case "BJDC_SFGG":
												tSelectType = "sfggSelect";
												break;
											case "BJDC_SPF":
												tSelectType = "spfSelect";
												break;
											case "BJDC_BF":
												tSelectType = "dcbfSelect";
												break;
											case "BJDC_JQS":
												tSelectType = "jqsSelect";
												break;
											case "BJDC_BQC":
												tSelectType = "bqcspfSelect";
												break;
										}
										if (tSelectType) {
											console.log(Key)
											let tOptionObj = {
												award: tOptions[n].award,
												key: Key,
												name: OPTIONS[Key].name
											}
											if (tObj[tSelectType] && tObj[tSelectType].length) {
												tObj[tSelectType].push(tOptionObj)
											} else {
												tObj[tSelectType] = [];
												tObj[tSelectType].push(tOptionObj)
											}
										}
									}
								}
							}
						}
						tArr.push(tObj);
					}
					aMatchSelect.value[PLAYWAY] = tArr;
					store.dispatch("allPlay/UBJDCSelected", []);
				}
			};
			fReverse();

			// 获取组合后的串关
			const fGetMixTypes = () => {
				const t_aStrandSelect = aStrandSelect.value;
				console.log(aStrandSelect);
				// 赛事过关方式组合
				let tSMixTypes = "";
				if (t_aStrandSelect.length === 1 && t_aStrandSelect[0] === 1) {
					tSMixTypes = "单关"
				} else {
					for (let i = 0, j = t_aStrandSelect.length; i < j; i++) {
						if (tSMixTypes) {
							tSMixTypes += "^" + t_aStrandSelect[i] + "串1";
						} else {
							// 如果第一个是1
							if (t_aStrandSelect[i] === 1) {
								tSMixTypes = "单关";
							} else {
								tSMixTypes = t_aStrandSelect[i] + "串1";
							}
						}
					}
				}
				console.log(tSMixTypes)
				return tSMixTypes
			};
			// 计算
			const fCalculate = () => {
				console.log("第三步：再执行请求数据")
				let tSMixTypes = fGetMixTypes();
				Toast({
					type: "loading"
				});
				apiPlanCalculate({
					itemList: aMergeData.value,
					lotteryTypeEnum: "BJDC",
					lotterySubTypeEnum: LOTTERYSUBTYPEENUM,
					mixTypes: tSMixTypes,
					multiple: 1
				}).then((res) => {
					console.log(res);
					let tData = res.result ? res.result : {};
					const orderData = store.state.allPlay.orderData;
					let tMultiple = orderData.multiple ? orderData.multiple : oPlanDetail.calculate.multiple;
					oPlanDetail.calculate.multiple = tMultiple <= tData.minBetMultiple ?
						tData.minBetMultiple : tMultiple;
					oPlanDetail.calculate.minBetMultiple = tData.minBetMultiple;
					oPlanDetail.calculate.minTotalFee = tData.minTotalFee > 0 ? tData.minTotalFee : null;
					oPlanDetail.calculate.prePrizeAmount = tData.prePrizeAmount;
					oPlanDetail.calculate.prePrizeAmountMin = tData.prePrizeAmountMin;
					oPlanDetail.calculate.totalFee = tData.totalFee;
				})
			};
			// 合并数据
			const fMergeData = () => {
				console.log("第二步：先执行合并数据")
				let t_aMatchSelect = aMatchSelect.value[PLAYWAY];
				let tArr = [];
				console.log(t_aMatchSelect)
				t_aMatchSelect.forEach((item) => {
					let tObj = {
						gameNo: item.gameNo,
						isDan: 0,
						issueNo: item.issueNo,
						matchId: item.id,
						itemDetailList: []
					};
					if (item[PLAYWAY + "Select"] && item[PLAYWAY + "Select"].length > 0) {
						item[PLAYWAY + "Select"].forEach(itemA => {
							const {
								lotteryResultEnum,
								lotterySubTypeEnum
							} = OPTIONS[itemA.key];
							tObj.itemDetailList.push({
								award: itemA.award,
								lotteryResultEnum,
								lotterySubTypeEnum
							})
						})
					}
					tArr.push(tObj);
				})
				aMergeData.value = tArr;
				fCalculate();
			};
			// 关闭赛事(index: 当前赛事下标)
			const fCloseMatch = (index) => {
				let t_aMatchSelect = aMatchSelect.value[PLAYWAY];
				let tCAMatchSelect = fnDeepClone(t_aMatchSelect);
				tCAMatchSelect.splice(index, 1);
				aMatchSelect.value[PLAYWAY] = tCAMatchSelect;
				console.log(aMatchSelect.value[PLAYWAY]);
				// 同时更新store数据
				store.state.allPlay.BJDCSelected[PLAYWAY].splice(index, 1);
				console.log(store.state.allPlay.BJDCSelected[PLAYWAY]);
				store.dispatch("allPlay/UBJDCSelected", store.state.allPlay.BJDCSelected);
				fGetSelectStrand();
			};
			// 获取已选择的串关方式
			const fGetSelectStrand = () => {
				console.log("第一步：获取已选择的串关方式");
				let t_nLength = aMatchSelect.value[PLAYWAY].length;
				let t_nMaxStrand = t_nLength < OPTIONS_PLAYWAY[PLAYWAY].maxStrand ? t_nLength : OPTIONS_PLAYWAY[PLAYWAY].maxStrand;
				let t_nMinStrand = OPTIONS_PLAYWAY[PLAYWAY].minStrand;
				let t_aStrandSelect = [];
				console.log(t_nMaxStrand);
				// if (OPTIONS_PLAYWAY[PLAYWAY].minStrand === 1) t_aStrandSelect.push(1);

				for (let i = t_nMinStrand; i < (t_nMaxStrand+1); i++) {
					t_aStrandSelect.push(i)
				}
				console.log(t_aStrandSelect)
				aStrandSelect.value = t_aStrandSelect;
				if (aStrandSelect.value.length === 0) {
					oPlanDetail.isDisableCreate = true;
					oPlanDetail.calculate.prePrizeAmount = 0; // 理论最高奖金
					oPlanDetail.calculate.prePrizeAmountMin = 0; // 理论最低奖金
					oPlanDetail.calculate.totalFee = 0; // 方案投注金额
					return;
				}
				oPlanDetail.isDisableCreate = false;
				fMergeData();
			};

			// 选择串关方式
			const fSelectStrand = (Value) => {
				console.log("选择串关方式");
				let tCAStrandSelect = fnDeepClone(aStrandSelect.value);
				let tIdx = tCAStrandSelect.indexOf(Value);
				if (tIdx !== -1) {
					// 剩余最后一个串关方式则不可取消
					if (aStrandSelect.value.length === 1) return;
					tCAStrandSelect.splice(tIdx, 1);
				} else {
					tCAStrandSelect.push(Value);
				}
				tCAStrandSelect.sort();
				aStrandSelect.value = tCAStrandSelect;

				fMergeData();
			};
			/**
			 * fOperationOrder 操作订单
			 * @param {string} 发布：release;优化：optimize
			 * @return {null}
			 */
			// 操作订单 pType（发布：release;优化：optimize）
			const fOperationOrder = (pType) => {
				if (oPlanDetail.isDisableCreate) return Toast({
					message: "当前选择的赛事较少不支持下单",
					position: 'bottom'
				});
				const tMinTotalFee = oPlanDetail.calculate.minTotalFee;
				if (pType !== "optimize" && tMinTotalFee && (tMinTotalFee > calculate_totalFee.value)) {
					return Toast({
						message: "投注金额不能小于" + tMinTotalFee + "元",
						position: 'bottom'
					});
				} else {
					let tSMixTypes = fGetMixTypes();

					// 创建订单数据，并存储到vuex中
					let oOrderData = {
						itemList: aMergeData.value,
						lotterySubTypeEnum: LOTTERYSUBTYPEENUM,
						lotteryTypeEnum: "BJDC",
						mixTypes: tSMixTypes,
						aMixTypes: fnDeepClone(aStrandSelect.value),
						multiple: oPlanDetail.calculate.multiple,
						roleId: UserInfo.roleId,
						roleName: UserInfo.roleName,
						shopId: "1",
						sourceId: "",
						totalFee: calculate_totalFee.value,
						userId: UserInfo.userId,
						userName: UserInfo.realName,
						minTotalFee: tMinTotalFee,
					};
					console.log(oOrderData);
					store.dispatch("allPlay/UOrderData", oOrderData);
					router.push({
						path: '/' + pType
					});
				}
			};
			// 彩店出票
			const fCreateOrder = () => {
				if (oPlanDetail.isDisableCreate) return Toast({
					message: "当前选择的赛事较少不支持下单",
					position: 'bottom'
				});
				
				// 如果投注金额为0则终止
				if (calculate_totalFee.value <= 0) return;

				const tMinTotalFee = oPlanDetail.calculate.minTotalFee;
				if (tMinTotalFee && (tMinTotalFee > calculate_totalFee.value)) {
					return Toast({
						message: "投注金额不能小于" + tMinTotalFee + "元",
						position: 'bottom'
					});
				} else {
					Toast({
						type: "loading"
					});
					apiPlanLotteryShop({
						roleId: UserInfo.roleId,
						userId: UserInfo.userId,
						lotteryTypeEnum: "BJDC"
					}).then(res => {
						let tData = res.result;
						let tShopName = "";
						if (tData.canBet) {
							for (let i = 0, j = tData.lotteryShopList.length; i < j; i++) {
								if (i === 0) {
									tShopName = "" + tData.lotteryShopList[i].shopName
								} else {
									tShopName = tShopName + "/" + tData.lotteryShopList[i].shopName
								}
							}
							oPlanDetail.shopName = tShopName;
							oCPData.oYCData.dialogCA.showDialog = true;
						}
					});
				}
			}

			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						// 跳转至跟单页面
						console.log("跳转至跟单页面");
						oCPData.oYCData.dialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						// 关闭弹框
						console.log("关闭弹框");
						oCPData.oYCData.dialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCA") {
					console.log(1111111)
					if (action === "confirm") {
						// 确定创建订单
						let tSMixTypes = fGetMixTypes();
						Toast({
							type: "loading"
						});
						apiPlanCreate({
							canCopy: 0,
							itemList: aMergeData.value,
							lotterySubTypeEnum: LOTTERYSUBTYPEENUM,
							lotteryTypeEnum: "BJDC",
							mixTypes: tSMixTypes,
							multiple: oPlanDetail.calculate.multiple,
							profitProtectRate: 0,
							roleId: UserInfo.roleId,
							roleName: UserInfo.roleName,
							shopId: "1",
							sourceId: "",
							totalFee: calculate_totalFee.value,
							userId: UserInfo.userId,
							userName: UserInfo.realName
						}).then(res => {
							let tData = res.result ? res.result : {};
							oPayRelated.payDetail.availableAmount = tData.availableAmount ? tData
								.availableAmount : 0;
							oPayRelated.payDetail.totalFee = tData.totalFee ? tData.totalFee : 0;
							oPayRelated.payDetail.prePrizeAmount = tData.prePrizeAmount ? tData
								.prePrizeAmount : 0;
							oPayRelated.payDetail.prePrizeAmountMin = tData.prePrizeAmountMin ? tData
								.prePrizeAmountMin : 0;
							oPayRelated.payDetail.planId = tData.planId;
							oPayRelated.payDetail.isEnough = tData.isEnough;
							oCPData.oYCData.dialogCA.showDialog = false;
							store.dispatch("allPlay/UBJDCSelected", null);
							oPayRelated.showDialog = true;
							fn(false)
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCC") {
					if (action === "confirm") {
						oCPData.oYCData.dialogCC.showDialog = false;
						fn(false);
						// 跳转支付佣金界面
						router.replace({
							path: '/recordGD',
							query: {
								redirect: 'mine'
							}
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCC.showDialog = false;
						fn(false);
					}
				} else if (dialogKey === "dialogCD") {
					if (action === "confirm") {
						oPayRelated.showDialog = false;
						oCPData.oYCData.dialogCD.showDialog = false;
						fn(false);
						// 跳转方案列表页
						router.replace({
							path: '/plan'
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCD.showDialog = false;
						fn(false);
					}
				}
			};
			/* fBeforeCloseActionSheetA ActionSheetA关闭之前
			 * action 操作的按钮（confirm：确定；cancel：取消；overlay：点击遮罩层）
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fBeforeCloseActionSheetA = (action, fn) => {
				if (action === 'confirm') {
					oPayRelated.showDialog = false;
					fn(false)
				} else if (action === 'cancel') {
					oCPData.oYCData.dialogCD.showDialog = true;
					fn(true)
				}
			};
			/* fSubmitActionSheetA 支付的动作面板中的支付按钮
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fSubmitActionSheetA = (fn) => {
				// 该订单是否可支付
				if (!oPayRelated.payDetail.isEnough) return;
				Toast({
					type: "loading"
				});
				// 订单支付
				apiOrderPay({
					balance: oPayRelated.payDetail.totalFee,
					otherFee: 0,
					payType: 1,
					planId: oPayRelated.payDetail.planId,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oPayRelated.showDialog = false;
					fn(false);
					let tData = res.result;
					// 是否支付成功
					if (tData["payResult"]) {
						// 成功
						Toast({
							message: tData.message ? tData.message : "支付成功",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						});
					} else if (tData["orderUnPayReasonEnum"] === "HAVE_UN_PAY_FEE") {
						// 失败:需要支付佣金--打开跳转到佣金弹框
						oCPData.oYCData.dialogCC.showDialog = true;
					} else {
						Toast({
							message: tData.message ? tData.message : "支付失败",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						})
					}
				})
			};


			// 支付相关信息
			const oPayRelated = reactive({
				payDetail: {
					availableAmount: 0, // 店内余额
					totalFee: 0, // 方案金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					planId: null, // 方案ID
					isEnough: false, // 是否可以支付
					lotteryType: "JCZQ",
				},
				oButton: {
					isShow: true,
					text: "确定"
				},
				showDialog: false, //是否展示弹框
				title: "彩店出票"
			});


			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
				store.dispatch("allPlay/UOrderData", {});
			};
			
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.path === "/beijingball") {
					to.meta.resetType = "0";
					return true
				}
			})
			fGetSelectStrand();
			return {
				OPTIONS_PLAYWAY,
				PLAYWAY,

				oCPData,
				aMatchSelect,
				aStrandSelect,

				oPayRelated,
				oPlanDetail,

				calculate_prePrizeAmount,
				calculate_prePrizeAmountMin,
				calculate_totalFee,

				fCloseMatch,
				fSelectStrand,

				fOperationOrder,
				fCreateOrder,

				fBeforeCloseActionSheetA,
				fSubmitActionSheetA,
				fBeforeClose,
				fLeft,
				fnKeepFigures
			}
		}
	}
</script>

<style scoped lang="scss">
	.bet {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.bet_body {
			padding: 0.2933rem 0.32rem 0;

			.bet_body_match {
				background: #FFFFFF;
				border-radius: 0.2133rem;
				margin-bottom: 0.4266rem;

				.bet_body_match_title {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0.32rem 0;
					background: #FAFAFA;
					border-radius: 0.2133rem 0.2133rem 0px 0px;

					&>span {
						flex: 1;
						font-size: 0.4266rem;
						font-weight: 500;
						color: #383838;
					}

					&>span:first-child {
						text-align: right;
					}

					&>span:last-child {
						text-align: left;
					}

					.bet_body_match_title_b {
						width: 0.3733rem;
						height: auto;
						margin: 0 0.32rem;
					}

					.bet_body_match_title_d {
						position: absolute;
						right: 0.4rem;
						top: 0;
						bottom: 0;
						margin: auto 0;
						width: 0.4266rem;
						height: 0.4266rem;
					}
				}

				.bet_body_match_content {
					padding: 0.4rem;
					border-radius: 0px 0px 0.2133rem 0.2133rem;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.bet_body_match_content_a {
						flex: 1;
						display: flex;
						flex-wrap: wrap;
						align-items: center;

						.bet_body_match_content_aa {
							width: 2.4533rem;
							height: 1.1733rem;
							box-sizing: border-box;
							margin-right: 0.08rem;
							background: #FFE4E3;
							border-radius: 0.1066rem;
							border: 0.0266rem solid #E2335D;
							display: flex;
							align-items: center;
							flex-direction: column;
							justify-content: center;
							margin-bottom: 0.1066rem;

							&>p:first-child {
								font-size: 0.4rem;
								font-weight: 500;
								color: #383838;
								line-height: 0.5333rem;
							}

							&>p:last-child {
								margin-top: 0.0266rem;
								font-size: 0.3733rem;
								font-weight: bold;
								color: #E2335D;
								line-height: 0.4266rem;

								display: flex;
								align-items: center;
							}
						}

						.bet_body_match_content_ab {
							&>p:last-child {
								&>em {
									display: flex;
									align-items: center;
									max-height: 0.3733rem;
									box-sizing: border-box;
									padding: 0.0266rem 0.16rem;
									background: #E2335D;
									border-radius: 0.1866rem;
									border: 0.0266rem solid #E2335D;
									margin-right: 0.0533rem;
									font-size: 0.2666rem;
									font-weight: bold;
									color: #FFFFFF;
									line-height: 0.2933rem;
								}
							}
						}
					}
				}
			}
		}

		.bet_foot {
			position: relative;
			padding: 0.4rem 0.4rem 0;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;

			.bet_foot_a {
				padding-bottom: 0.4rem;
				display: flex;
				align-items: flex-start;

				border-bottom: 0.0266rem solid #FAFAFA;

				.bet_foot_a_content {
					margin-top: -0.2666rem;
					flex: 1;
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					&>em {
						width: 1.7066rem;
						box-sizing: border-box;
						
						margin-top: 0.2666rem;
						text-align: center;

						border: 0.0266rem solid #FF7671;
						background: #FFFFFF;
						border-radius: 0.1066rem;

						font-size: 0.32rem;
						font-weight: 400;
						color: #FF7671;
						line-height: 0.4533rem;
						margin-right: 0.1066rem;
					}

					.bet_foot_a_content_select {
						background: #FF7671;
						color: #FFFFFF;
					}
				}

				.bet_foot_a_style {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;
				}
			}

			.bet_foot_b {
				padding: 0.2133rem 0;
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;

				&>em {
					margin: 0 0.1066rem;
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
				}
			}

			.bet_foot_c {
				padding: 0.24rem 0;
				text-align: center;
				
				font-size: 0.3733rem;
				font-weight: 400;
				color: #979797;
				line-height: 0.5333rem;

				&>em {
					margin-left: 0.1066rem;
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
				}
			}

			.bet_foot_d {
				padding: 0.16rem 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.bet_foot_d_a {
					margin-left: 0.2133rem;
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;

					&>span {
						box-sizing: border-box;
						display: inline-block;
						text-align: center;
						width: 2.6933rem;
						height: 1.0133rem;
						line-height: 1.0133rem;
					}

					.bet_foot_d_a_a {
						text-align: left;
						font-size: 0.48rem;
						font-weight: bold;
						color: #E2335D;
					}

					.bet_foot_d_a_b {
						background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
						border-radius: 0.6666rem;

						font-size: 0.3733rem;
						font-weight: 500;
						color: #FFFFFF;
					}

					// .bet_foot_d_a--disabled {
					// 	background: #FFABA8;
					// }
				}
			}
		}

		.bet_foot::after {
			content: "";
			position: absolute;
			top: -0.5866rem;
			left: 0;
			width: 100%;
			height: 0.5866rem;
			background: linear-gradient(180deg, rgba(241, 242, 247, 0) 0%, #E6E7EA 100%);
		}

		.bet_tips {
			padding: 0.2666rem 0.64rem 0.88rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}

		.bet_tips_a {
			padding: 0.2666rem 0.64rem 0.8rem;

			&>p {
				margin-top: 0.8rem;
				font-size: 0.4266rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5866rem;
			}
		}

		.bet_dialogCD {
			text-align: center;
		}
	}
</style>
