<template>
	<div class="beijingball app_page app_flex">
		<div class="app_title_c">
			<van-sticky>
				<van-nav-bar left-arrow right-text="玩法规则" @click-right="fRight" safe-area-inset-top>
					<template #left>
						<img class="app_title_c_left_icon" src="~@/assets/icon/icon-008.png" @click="fLeft">
						<img class="app_title_c_left_img" src="~@/assets/images/img_037.png">
					</template>
					<template #right>
						<span class="app_title_c_right_title">玩法规则</span>
					</template>
				</van-nav-bar>
			</van-sticky>
		</div>
		
		
		<div class="beijingball_body app_scroll">
			<van-tabs v-model:active="sActive" swipeable lazy-render @rendered="fOnRendered" swipe-threshold="4">
				<van-tab title="胜负过关" name="sfgg">
					<div v-if="oMatch.sfgg.aMatch.length" class="beijingball_body_tab">
						<van-collapse v-model="oMatch.sfgg.nActiveNames" :border="false">
							<van-collapse-item v-for="(item,index) in oMatch.sfgg.aMatch" class="beijingball_day"
								:class="{'beijingball_dayC': oMatch.sfgg.nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
								:key="index">
								<template #title>
									<div class="beijingball_day_title">
										<div class="beijingball_day_title_a">
											<span class="beijingball_day_title_a_date">
												{{item.issueNo}}
											</span>
										</div>
										<span v-show="oMatch.sfgg.nActiveNames.indexOf(item.issueNo) !== -1" class="beijingball_day_title_b">
											共<em>{{item.responseList.length}}</em>场比赛
										</span>
									</div>
									<div v-show="oMatch.sfgg.nActiveNames.indexOf(item.issueNo) === -1" class="beijingball_day_close">
										当日共 <em>{{item.responseList.length}}</em> 场比赛
									</div>
								</template>
								<template #right-icon>
									<div class="beijingball_day_rIcon" v-show="oMatch.sfgg.nActiveNames.indexOf(item.issueNo) !== -1">
										<span class="beijingball_day_rIcon_text">收起</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
									</div>
									<div class="beijingball_day_rIcon" v-show="oMatch.sfgg.nActiveNames.indexOf(item.issueNo) === -1">
										<span class="beijingball_day_rIcon_text">展开</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
									</div>
								</template>
								<div class="beijingball_options" v-for="(itemA, indexA) in item.responseList"
									:key="indexA">
									<div class="beijingball_options_title">
										<div class="beijingball_options_title_a">
											<span>{{itemA.hostShortName}}</span>
											<img src="~@/assets/icon/icon-011.png">
											<span>{{itemA.guestShortName}}</span>
										</div>
										<div class="beijingball_options_title_b">{{itemA.deadlineStr}}截止</div>
										<div class="beijingball_options_title_c">
											<img class="beijingball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
											<div>
												<em>{{itemA.gameNo}}</em>
												<span>{{itemA.gameName}}</span>
												<em>{{itemA.matchType}}</em>
											</div>
											<img class="beijingball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
										</div>
									</div>
					
									<div class="beijingball_options_content">
										<div class="beijingball_options_content_two">
											<div class="beijingball_options_content_two_type">
												<p class="beijingball_options_content_two_type_a">{{itemA.rang}}</p>
												<p class="beijingball_options_content_two_type_b">{{itemA.rangUnit}}</p>
											</div>
											<div class="beijingball_options_content_two_typeOption">
												<template v-for="(itemB,indexB) in oCPData.sfgg" :key="indexB">
													<div :class="{'beijingball_check': itemA.sfgg && itemA.sfgg[itemB.select]}" @click="fSelectOptions(index,indexA,itemB,'sfgg','sfggAllSelect')">
														<p>{{itemB.name}}</p>
														<p>
															{{itemA.sfgg && itemA.sfgg[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll" :class="{'beijingball_typeAll--check' : itemA.sfggAllSelect === oCPData.sfgg.length}" @click="fSelectAll(index,indexA,'sfgg','sfggAllSelect','sfgg')">
												全选
											</div>
										</div>
									</div>
								</div>
							</van-collapse-item>
						</van-collapse>
					</div>
					<div v-else class="beijingball_none">
						<van-empty class="beijingball_none_empty" :image="require('@/assets/images/img_021.png')"
							description="暂无赛事" />
					</div>
				</van-tab>
				<van-tab title="胜平负" name="spf">
					<div v-if="oMatch.sfgg.aMatch.length" class="beijingball_body_tab">
						<van-collapse v-model="oMatch.spf.nActiveNames" :border="false">
							<van-collapse-item v-for="(item,index) in oMatch.spf.aMatch" class="beijingball_day"
								:class="{'beijingball_dayC': oMatch.spf.nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
								:key="index">
								<template #title>
									<div class="beijingball_day_title">
										<div class="beijingball_day_title_a">
											<span class="beijingball_day_title_a_date">
												{{item.issueNo}}
											</span>
										</div>
										<span v-show="oMatch.spf.nActiveNames.indexOf(item.issueNo) !== -1" class="beijingball_day_title_b">
											共<em>{{item.responseList.length}}</em>场比赛
										</span>
									</div>
									<div v-show="oMatch.spf.nActiveNames.indexOf(item.issueNo) === -1" class="beijingball_day_close">
										当日共 <em>{{item.responseList.length}}</em> 场比赛
									</div>
								</template>
								<template #right-icon>
									<div class="beijingball_day_rIcon" v-show="oMatch.spf.nActiveNames.indexOf(item.issueNo) !== -1">
										<span class="beijingball_day_rIcon_text">收起</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
									</div>
									<div class="beijingball_day_rIcon" v-show="oMatch.spf.nActiveNames.indexOf(item.issueNo) === -1">
										<span class="beijingball_day_rIcon_text">展开</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
									</div>
								</template>
								<div class="beijingball_options" v-for="(itemA, indexA) in item.responseList"
									:key="indexA">
									<div class="beijingball_options_title">
										<div class="beijingball_options_title_a">
											<span>{{itemA.hostShortName}}</span>
											<img src="~@/assets/icon/icon-011.png">
											<span>{{itemA.guestShortName}}</span>
										</div>
										<div class="beijingball_options_title_b">{{itemA.deadlineStr}}截止</div>
										<div class="beijingball_options_title_c">
											<img class="beijingball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
											<div>
												<em>{{itemA.gameNo}}</em>
												<span>{{itemA.gameName}}</span>
												<em>{{itemA.matchType}}</em>
											</div>
											<img class="beijingball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
										</div>
									</div>
					
									<div class="beijingball_options_content">
										<div class="beijingball_options_content_three">
											<div class="beijingball_options_content_three_type">
												<p class="beijingball_options_content_three_type_a">{{itemA.rang}}</p>
												<p class="beijingball_options_content_three_type_b">{{itemA.rangUnit}}</p>
											</div>
											<div class="beijingball_options_content_three_typeOption">
												<template v-for="(itemB,indexB) in oCPData.spf" :key="indexB">
													<div :class="{'beijingball_check': itemA.spf && itemA.spf[itemB.select]}"
														@click="fSelectOptions(index,indexA,itemB,'spf','spfAllSelect')">
														<p>{{itemB.name}}</p>
														<p>
															{{itemA.spf && itemA.spf[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll" :class="{'beijingball_typeAll--check' : itemA.spfAllSelect === oCPData.spf.length}" @click="fSelectAll(index,indexA,'spf','spfAllSelect','spf')">
												全选
											</div>
										</div>
									</div>
								</div>
							</van-collapse-item>
						</van-collapse>
					</div>
					<div v-else class="beijingball_none">
						<van-empty class="beijingball_none_empty" :image="require('@/assets/images/img_021.png')"
							description="暂无赛事" />
					</div>
				</van-tab>
				<van-tab title="总进球" name="jqs">
					<div v-if="oMatch.jqs.aMatch.length" class="beijingball_body_tab">
						<van-collapse v-model="oMatch.jqs.nActiveNames" :border="false">
							<van-collapse-item v-for="(item,index) in oMatch.jqs.aMatch" class="beijingball_day"
								:class="{'beijingball_dayC': oMatch.jqs.nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
								:key="index">
								<template #title>
									<div class="beijingball_day_title">
										<div class="beijingball_day_title_a">
											<span class="beijingball_day_title_a_date">
												{{item.issueNo}}
											</span>
										</div>
										<span v-show="oMatch.jqs.nActiveNames.indexOf(item.issueNo) !== -1" class="beijingball_day_title_b">
											共<em>{{item.responseList.length}}</em>场比赛
										</span>
									</div>
									<div v-show="oMatch.jqs.nActiveNames.indexOf(item.issueNo) === -1" class="beijingball_day_close">
										当日共 <em>{{item.responseList.length}}</em> 场比赛
									</div>
								</template>
								<template #right-icon>
									<div class="beijingball_day_rIcon" v-show="oMatch.jqs.nActiveNames.indexOf(item.issueNo) !== -1">
										<span class="beijingball_day_rIcon_text">收起</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
									</div>
									<div class="beijingball_day_rIcon" v-show="oMatch.jqs.nActiveNames.indexOf(item.issueNo) === -1">
										<span class="beijingball_day_rIcon_text">展开</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
									</div>
								</template>
								<div class="beijingball_options" v-for="(itemA, indexA) in item.responseList"
									:key="indexA">
									<div class="beijingball_options_title">
										<div class="beijingball_options_title_a">
											<span>{{itemA.hostShortName}}</span>
											<img src="~@/assets/icon/icon-011.png">
											<span>{{itemA.guestShortName}}</span>
										</div>
										<div class="beijingball_options_title_b">{{itemA.deadlineStr}}截止</div>
										<div class="beijingball_options_title_c">
											<img class="beijingball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
											<div>
												<em>{{itemA.gameNo}}</em>
												<span>{{itemA.gameName}}</span>
												<em>{{itemA.matchType}}</em>
											</div>
											<img class="beijingball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
										</div>
									</div>
					
									<div class="beijingball_options_content">
										<div class="beijingball_options_content_flex4">
											<div class="beijingball_options_content_flex4_typeOption">
												<template v-for="(itemB,indexB) in oCPData.jqs" :key="indexB">
													<div :class="{'beijingball_check': itemA.jqs && itemA.jqs[itemB.select]}"
														@click="fSelectOptions(index,indexA,itemB,'jqs','jqsAllSelect')">
														<p>{{itemB.name}}</p>
														<p>
															{{itemA.jqs && itemA.jqs[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll" :class="{'beijingball_typeAll--check' : itemA.jqsAllSelect === oCPData.jqs.length}" @click="fSelectAll(index,indexA,'jqs','jqsAllSelect','jqs')">
												全选
											</div>
										</div>
									</div>
								</div>
							</van-collapse-item>
						</van-collapse>
					</div>
					<div v-else class="beijingball_none">
						<van-empty class="beijingball_none_empty" :image="require('@/assets/images/img_021.png')"
							description="暂无赛事" />
					</div>
				</van-tab>
				<van-tab title="半全场" name="bqcspf">
					<div v-if="oMatch.bqcspf.aMatch.length" class="beijingball_body_tab">
						<van-collapse v-model="oMatch.bqcspf.nActiveNames" :border="false">
							<van-collapse-item v-for="(item,index) in oMatch.bqcspf.aMatch" class="beijingball_day"
								:class="{'beijingball_dayC': oMatch.bqcspf.nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
								:key="index">
								<template #title>
									<div class="beijingball_day_title">
										<div class="beijingball_day_title_a">
											<span class="beijingball_day_title_a_date">
												{{item.issueNo}}
											</span>
										</div>
										<span v-show="oMatch.bqcspf.nActiveNames.indexOf(item.issueNo) !== -1" class="beijingball_day_title_b">
											共<em>{{item.responseList.length}}</em>场比赛
										</span>
									</div>
									<div v-show="oMatch.bqcspf.nActiveNames.indexOf(item.issueNo) === -1" class="beijingball_day_close">
										当日共 <em>{{item.responseList.length}}</em> 场比赛
									</div>
								</template>
								<template #right-icon>
									<div class="beijingball_day_rIcon" v-show="oMatch.bqcspf.nActiveNames.indexOf(item.issueNo) !== -1">
										<span class="beijingball_day_rIcon_text">收起</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
									</div>
									<div class="beijingball_day_rIcon" v-show="oMatch.bqcspf.nActiveNames.indexOf(item.issueNo) === -1">
										<span class="beijingball_day_rIcon_text">展开</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
									</div>
								</template>
								<div class="beijingball_options" v-for="(itemA, indexA) in item.responseList"
									:key="indexA">
									<div class="beijingball_options_title">
										<div class="beijingball_options_title_a">
											<span>{{itemA.hostShortName}}</span>
											<img src="~@/assets/icon/icon-011.png">
											<span>{{itemA.guestShortName}}</span>
										</div>
										<div class="beijingball_options_title_b">{{itemA.deadlineStr}}截止</div>
										<div class="beijingball_options_title_c">
											<img class="beijingball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
											<div>
												<em>{{itemA.gameNo}}</em>
												<span>{{itemA.gameName}}</span>
												<em>{{itemA.matchType}}</em>
											</div>
											<img class="beijingball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
										</div>
									</div>
					
									<div class="beijingball_options_content">
										<div class="beijingball_options_content_flex3">
											<div class="beijingball_options_content_flex3_typeOption">
												<template v-for="(itemB,indexB) in oCPData.bqcspf" :key="indexB">
													<div :class="{'beijingball_check': itemA.bqcspf && itemA.bqcspf[itemB.select]}"
														@click="fSelectOptions(index,indexA,itemB,'bqcspf','bqcspfAllSelect')">
														<p>{{itemB.name}}</p>
														<p>
															{{itemA.bqcspf && itemA.bqcspf[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll" :class="{'beijingball_typeAll--check' : itemA.bqcspfAllSelect === oCPData.bqcspf.length}" @click="fSelectAll(index,indexA,'bqcspf','bqcspfAllSelect','bqcspf')">
												全选
											</div>
										</div>
									</div>
								</div>
							</van-collapse-item>
						</van-collapse>
					</div>
					<div v-else class="beijingball_none">
						<van-empty class="beijingball_none_empty" :image="require('@/assets/images/img_021.png')"
							description="暂无赛事" />
					</div>
				</van-tab>
				<van-tab title="单场比分" name="dcbf">
					<div v-if="oMatch.dcbf.aMatch.length" class="beijingball_body_tab">
						<van-collapse v-model="oMatch.dcbf.nActiveNames" :border="false">
							<van-collapse-item v-for="(item,index) in oMatch.dcbf.aMatch" class="beijingball_day"
								:class="{'beijingball_dayC': oMatch.dcbf.nActiveNames.indexOf(item.issueNo) === -1 }" :name="item.issueNo"
								:key="index">
								<template #title>
									<div class="beijingball_day_title">
										<div class="beijingball_day_title_a">
											<span class="beijingball_day_title_a_date">
												{{item.issueNo}}
											</span>
										</div>
										<span v-show="oMatch.dcbf.nActiveNames.indexOf(item.issueNo) !== -1" class="beijingball_day_title_b">
											共<em>{{item.responseList.length}}</em>场比赛
										</span>
									</div>
									<div v-show="oMatch.dcbf.nActiveNames.indexOf(item.issueNo) === -1" class="beijingball_day_close">
										当日共 <em>{{item.responseList.length}}</em> 场比赛
									</div>
								</template>
								<template #right-icon>
									<div class="beijingball_day_rIcon" v-show="oMatch.dcbf.nActiveNames.indexOf(item.issueNo) !== -1">
										<span class="beijingball_day_rIcon_text">收起</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-009.png">
									</div>
									<div class="beijingball_day_rIcon" v-show="oMatch.dcbf.nActiveNames.indexOf(item.issueNo) === -1">
										<span class="beijingball_day_rIcon_text">展开</span>
										<img class="beijingball_day_rIcon_icon" src="~@/assets/icon/icon-010.png">
									</div>
								</template>
								<div class="beijingball_options" v-for="(itemA, indexA) in item.responseList"
									:key="indexA">
									<div class="beijingball_options_title">
										<div class="beijingball_options_title_a">
											<span>{{itemA.hostShortName}}</span>
											<img src="~@/assets/icon/icon-011.png">
											<span>{{itemA.guestShortName}}</span>
										</div>
										<div class="beijingball_options_title_b">{{itemA.deadlineStr}}截止</div>
										<div class="beijingball_options_title_c">
											<img class="beijingball_options_title_c_lIcon" src="~@/assets/icon/icon-013.png">
											<div>
												<em>{{itemA.gameNo}}</em>
												<span>{{itemA.gameName}}</span>
												<em>{{itemA.matchType}}</em>
											</div>
											<img class="beijingball_options_title_c_rIcon" src="~@/assets/icon/icon-014.png">
										</div>
									</div>
					
									<div class="beijingball_options_content">
										<div class="beijingball_options_content_flex5">
											<div class="beijingball_options_content_flex5_typeOther">主胜</div>
											<div class="beijingball_options_content_flex5_typeOption beijingball_options_content_flex5_typeSF">
												<template v-for="(itemB,indexB) in oCPData.dcbfZS" :key="indexB">
													<div :class="{'beijingball_check': itemA.dcbf && itemA.dcbf[itemB.select]}"
														@click="fSelectOptions(index,indexA,itemB,'dcbf','dcbfZSAllSelect')">
														<p>{{itemB.name}}</p>
														<p class="app_scale_10">
															{{itemA.dcbf && itemA.dcbf[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll beijingball_typeAll_min" :class="{'beijingball_typeAll--check' : itemA.dcbfZSAllSelect === oCPData.dcbfZS.length}" @click="fSelectAll(index,indexA,'dcbf','dcbfZSAllSelect','dcbfZS')">
												全选
											</div>
										</div>
										<div class="beijingball_options_content_flex5">
											<div class="beijingball_options_content_flex5_typeOther">平</div>
											<div class="beijingball_options_content_flex5_typeOption beijingball_options_content_flex5_typeP">
												<template v-for="(itemB,indexB) in oCPData.dcbfP" :key="indexB">
													<div :class="{'beijingball_check': itemA.dcbf && itemA.dcbf[itemB.select] }"
														@click="fSelectOptions(index,indexA,itemB,'dcbf','dcbfPAllSelect')">
														<p>{{itemB.name}}</p>
														<p class="app_scale_10">
															{{itemA.dcbf && itemA.dcbf[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll beijingball_typeAll_min" :class="{'beijingball_typeAll--check' : itemA.dcbfPAllSelect === oCPData.dcbfP.length}" @click="fSelectAll(index,indexA,'dcbf','dcbfPAllSelect','dcbfP')">
												全选
											</div>
										</div>
										<div class="beijingball_options_content_flex5">
											<div class="beijingball_options_content_flex5_typeOther">主负</div>
											<div class="beijingball_options_content_flex5_typeOption beijingball_options_content_flex5_typeSF">
												<template v-for="(itemB,indexB) in oCPData.dcbfZF" :key="indexB">
													<div :class="{'beijingball_check': itemA.dcbf && itemA.dcbf[itemB.select] }"
														@click="fSelectOptions(index,indexA,itemB,'dcbf','dcbfZFAllSelect')">
														<p>{{itemB.name}}</p>
														<p class="app_scale_10">
															{{itemA.dcbf && itemA.dcbf[itemB.key]}}
														</p>
													</div>
												</template>
											</div>
											<div class="beijingball_typeAll beijingball_typeAll_min" :class="{'beijingball_typeAll--check' : itemA.dcbfZFAllSelect === oCPData.dcbfZF.length}" @click="fSelectAll(index,indexA,'dcbf','dcbfZFAllSelect','dcbfZF')"
											>
												全选
											</div>
										</div>
									</div>
								</div>
							</van-collapse-item>
						</van-collapse>
					</div>
					<div v-else class="beijingball_none">
						<van-empty class="beijingball_none_empty" :image="require('@/assets/images/img_021.png')"
							description="暂无赛事" />
					</div>
				</van-tab>
			</van-tabs>
		</div>
		

		<div class="beijingball_bottom">
			<div class="beijingball_bottom_left">
				<img src="~@/assets/icon/icon-015.png" @click="fCleanAll">
				<template v-if="aMatchSelect && aMatchSelect[sActive]">
					<span v-if="aMatchSelect[sActive].length > 0">已选 <em class="beijingball_bottom_left_em2">{{aMatchSelect[sActive].length}}</em> 场</span>
					<span v-else-if="aMatchSelect[sActive].length === 0">
						至少选择 <em class="beijingball_bottom_left_em2">{{OPTIONS[sActive].minSelect}}</em> 场比赛</span>
				</template>
				<span v-else>
					至少选择 <em class="beijingball_bottom_left_em2">{{OPTIONS[sActive].minSelect}}</em> 场比赛</span>
			</div>
			<span class="beijingball_bottom_right" :class="{'beijingball_bottom_right--disabled': bIsDisabled}"
				@click="fBet">
				立即投注
			</span>
		</div>
		<h-dialog-c title="提示" dialogKey="dialogC" :show="dialogC.showDialog"
			:showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText"
			@fBeforeClose="fBeforeClose">
			<div class="beijingball_tips">
				当前有未提交的购彩信息，如果执行返回您将自动放弃购彩信息！
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onActivated,
		inject,
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnDeepClone,
		fnObjForArraySort
	} from "@/utils/public.js";
	import {
		bjdcPlayWay as OPTIONS
	} from "@/utils/options.js";
	import {
		apiBJDC
	} from '@/api/allplay.js';
	export default {
		name: "Beijingball",
		components: {
			HDialogC
		},
		setup() {
			const route = useRoute(); //初始化路由
			const router = useRouter(); //初始化路由
			const fGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法

			// 当前tab的name
			const sActive = ref("sfgg");
			// 上次tab的name
			// const sActive_old = ref("sfgg");
			
			// 当前页面数据
			const oCPData = reactive({
				// 胜负
				sfgg: [{
						name: '胜',
						key: 'hostRqWinSp',
						select: 'hostRqWinSpSelect',
						sort: 0
					},
					{
						name: '负',
						key: 'guestRqWinSp',
						select: 'guestRqWinSpSelect',
						sort: 1
					}
				],
				// 胜平负
				spf: [{
						name: '胜',
						key: 'hostWinSp',
						select: 'hostWinSpSelect',
						sort: 2
					},
					{
						name: '平',
						key: 'drawSp',
						select: 'drawSpSelect',
						sort: 3
					},
					{
						name: '负',
						key: 'guestWinSp',
						select: 'guestWinSpSelect',
						sort: 4
					}
				],
				// 比分主胜
				dcbfZS: [{
						name: '1:0',
						key: 's01s00',
						select: 's01s00Select',
						sort: 6
					},
					{
						name: '2:0',
						key: 's02s00',
						select: 's02s00Select',
						sort: 7
					},
					{
						name: '2:1',
						key: 's02s01',
						select: 's02s01Select',
						sort: 8
					},
					{
						name: '3:0',
						key: 's03s00',
						select: 's03s00Select',
						sort: 9
					},
					{
						name: '3:1',
						key: 's03s01',
						select: 's03s01Select',
						sort: 10
					},
					{
						name: '3:2',
						key: 's03s02',
						select: 's03s02Select',
						sort: 11
					},
					{
						name: '4:0',
						key: 's04s00',
						select: 's04s00Select',
						sort: 12
					},
					{
						name: '4:1',
						key: 's04s01',
						select: 's04s01Select',
						sort: 13
					},
					{
						name: '4:2',
						key: 's04s02',
						select: 's04s02Select',
						sort: 14
					},
					{
						name: '胜其他',
						key: 's1sh',
						select: 's1shSelect',
						sort: 18
					}
				],
				// 比分平
				dcbfP: [{
						name: '0:0',
						key: 's00s00',
						select: 's00s00Select',
						sort: 19
					},
					{
						name: '1:1',
						key: 's01s01',
						select: 's01s01Select',
						sort: 20
					},
					{
						name: '2:2',
						key: 's02s02',
						select: 's02s02Select',
						sort: 21
					},
					{
						name: '3:3',
						key: 's03s03',
						select: 's03s03Select',
						sort: 22
					},
					{
						name: '平其他',
						key: 's1sd',
						select: 's1sdSelect',
						sort: 23
					}
				],
				// 比分主负
				dcbfZF: [{
						name: '0:1',
						key: 's00s01',
						select: 's00s01Select',
						sort: 24
					},
					{
						name: '0:2',
						key: 's00s02',
						select: 's00s02Select',
						sort: 25
					},
					{
						name: '1:2',
						key: 's01s02',
						select: 's01s02Select',
						sort: 26
					},
					{
						name: '0:3',
						key: 's00s03',
						select: 's00s03Select',
						sort: 27
					},
					{
						name: '1:3',
						key: 's01s03',
						select: 's01s03Select',
						sort: 28
					},
					{
						name: '2:3',
						key: 's02s03',
						select: 's02s03Select',
						sort: 29
					},
					{
						name: '0:4',
						key: 's00s04',
						select: 's00s04Select',
						sort: 30
					},
					{
						name: '1:4',
						key: 's01s04',
						select: 's01s04Select',
						sort: 31
					},
					{
						name: '2:4',
						key: 's02s04',
						select: 's02s04Select',
						sort: 32
					},
					{
						name: '负其他',
						key: 's1sa',
						select: 's1saSelect',
						sort: 36
					}
				],
				// 进球数
				jqs: [{
						name: '0',
						key: 's0',
						select: 's0Select',
						sort: 37
					},
					{
						name: '1',
						key: 's1',
						select: 's1Select',
						sort: 38
					},
					{
						name: '2',
						key: 's2',
						select: 's2Select',
						sort: 39
					},
					{
						name: '3',
						key: 's3',
						select: 's3Select',
						sort: 40
					},
					{
						name: '4',
						key: 's4',
						select: 's4Select',
						sort: 41
					},
					{
						name: '5',
						key: 's5',
						select: 's5Select',
						sort: 42
					},
					{
						name: '6',
						key: 's6',
						select: 's6Select',
						sort: 43
					},
					{
						name: '7+',
						key: 's7',
						select: 's7Select',
						sort: 44
					}
				],
				// 半全场
				bqcspf: [{
						name: '3-3',
						key: 'hh',
						select: 'hhSelect',
						sort: 45
					},
					{
						name: '3-1',
						key: 'hd',
						select: 'hdSelect',
						sort: 46
					},
					{
						name: '3-0',
						key: 'ha',
						select: 'haSelect',
						sort: 47
					},
					{
						name: '1-3',
						key: 'dh',
						select: 'dhSelect',
						sort: 48
					},
					{
						name: '1-1',
						key: 'dd',
						select: 'ddSelect',
						sort: 49
					},
					{
						name: '1-0',
						key: 'da',
						select: 'daSelect',
						sort: 50
					},
					{
						name: '0-3',
						key: 'ah',
						select: 'ahSelect',
						sort: 51
					},
					{
						name: '0-1',
						key: 'ad',
						select: 'adSelect',
						sort: 52
					},
					{
						name: '0-0',
						key: 'aa',
						select: 'aaSelect',
						sort: 53
					}
				]
			});
			
			// dialogC
			const dialogC = reactive({
				isSure: false, // 确定退出
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "继续返回"
			});

			// 所有赛事
			const oMatch = reactive({
				sfgg: {
					aMatch: [],// 赛事列表
					aMatch_BackUp: [],// 赛事列表-备份
					nActiveNames: []// van-collapse的唯一值
				},
				spf: {
					aMatch: [],
					aMatch_BackUp: [],
					nActiveNames: []
				},
				dcbf: {
					aMatch: [],
					aMatch_BackUp: [],
					nActiveNames: []
				},
				jqs: {
					aMatch: [],
					aMatch_BackUp: [],
					nActiveNames: []
				},
				bqcspf: {
					aMatch: [],
					aMatch_BackUp: [],
					nActiveNames: []
				},
			});
			// 已选择赛事信息
			const aMatchSelect = computed(() => {
				return store.state.allPlay.BJDCSelected
			});
			console.log(aMatchSelect.value)

			// 是否禁用立即投注按钮
			const bIsDisabled = ref(true);
			const fChangeDisabled = () => {
				// 已选场次长度
				const t_nlength = aMatchSelect.value[sActive.value] ? aMatchSelect.value[sActive.value].length : 0;
				console.log(t_nlength, OPTIONS[sActive.value].minSelect)
				if (t_nlength >= OPTIONS[sActive.value].minSelect) {
					bIsDisabled.value = false;
				} else {
					bIsDisabled.value = true;
				}
			};
			
			// 全部赛事
			const fGetMatch = () => {
				apiBJDC({
					code: OPTIONS[sActive.value].code
				}).then(res => {
					let tData = res && res.result ? res.result : [];
					console.log(tData);
					oMatch[sActive.value].aMatch = fnDeepClone(tData);
					if (tData.length > 0) oMatch[sActive.value].nActiveNames = [tData[0].issueNo];
					oMatch[sActive.value].aMatch_BackUp = fnDeepClone(tData);
					fEcho(false);
				})
			};

			// 标签内容首次渲染时触发（仅在开启延迟渲染后触发）
			const fOnRendered = () => {
				fGetMatch();
			};
			
			// 数据回显
			// IsReset 是否重置数据
			const fEcho = (IsReset) => {
				console.log("开始回显");
				let t_sActive = sActive.value;
				console.log(aMatchSelect.value);
				let tAMatchSelect = aMatchSelect.value[t_sActive];
				tAMatchSelect = tAMatchSelect ? tAMatchSelect : [];
				if (tAMatchSelect.length === 0) return;
				if (oMatch[t_sActive].aMatch.length === 0) return;
				if (IsReset) oMatch[t_sActive].aMatch = fnDeepClone(oMatch[t_sActive].aMatch_BackUp);
				let tAMatch = oMatch[t_sActive].aMatch;
				console.log(tAMatch)
				let tCAMatchSelect = fnDeepClone(tAMatchSelect);
				tCAMatchSelect.forEach((itemA, indexA) => {
					let tNoSame = false;
					tAMatch.forEach(itemB => {
						itemB.responseList.forEach((itemC, indexC) => {
							if (itemA.id === itemC.id) {
								tNoSame = true;
								// 如果玩法的过关开启，则合并选项，如果未开启，则使用新数据
								itemA[t_sActive] = {
									...itemA[t_sActive],
									...itemC[t_sActive]
								};
								itemB.responseList[indexC] = fnDeepClone(itemA);
							}
						})
					})
					if (!tNoSame) delete tAMatchSelect[indexA];
				})
				
				console.log(tAMatchSelect);
				// 处理后的数据
				let tHandleData = tAMatchSelect.filter(item => item !== undefined || item !== null);
				console.log(tHandleData);
				tAMatchSelect = fnDeepClone(tHandleData);
				store.dispatch("allPlay/UBJDCSelected", aMatchSelect.value);
				fChangeDisabled()
			};

			/**
			 * [fnHandleSelectData 处理选中数据]
			 * @param  {[String]} IsDo [是否开始 从aMatchSelect中合并或者移除] 
			 * @param  {[Object]} Match [当前赛事信息]
			 * @param  {[Object]} Option [当前比赛选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @param  {[String]} AllSelectName [当前比赛玩法全选的名称]
			 * @return {[Null]}
			 */
			const fnHandleSelectData = (IsDo, Match, Option, ObjName, AllSelectName) => {
				// console.log(IsDo, Match, Option, ObjName, AllSelectName)
				if (IsDo === "all" || IsDo === "first") {
					// 判断赛事是否已经达到限额
					// console.log(aMatchSelect.value)
					if (aMatchSelect.value[ObjName] && aMatchSelect.value[ObjName].length === OPTIONS[sActive.value].maxSelect) {
						// 当前点击玩法的赛事是否已添加到选项中
						let tAMatchSelect = aMatchSelect.value[ObjName];
						// 是否存在
						let tIsHas = tAMatchSelect.filter(item => item.id === Match.id);
						// 如果存在则提示
						if (tIsHas.length === 0 && !Match[ObjName][Option.select]) {
							return Toast({
								message: "最大可选"+ OPTIONS[sActive.value].maxSelect +"场比赛",
								position: 'bottom'
							});
						}
					}
					
					// 将当前点击的玩法反选
					Match[ObjName][Option.select] = !Match[ObjName][Option.select];
			
					// 计算已选中
					Match.selectNum = Match.selectNum ? Match.selectNum : 0;
					// 计算玩法全部选中的数量
					Match[AllSelectName] = Match[AllSelectName] ? Match[AllSelectName] : 0;
			
					// 当前赛事选中的选项是否为true
					if (Match[ObjName][Option.select]) {
						// console.log(Match[ObjName])
						// 组装数据，方便投注页面使用
						let tObj = {
							name: Option.name,
							award: Match[ObjName][Option.key],
							key: Option.key,
							sort: Option.sort,
						};
						// 将当前的玩法创建一个已选玩法数组，用于投注页面展示
						if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
							// 玩法选项是否存在
							let tIsHasOption = Match[ObjName + "Select"].filter(item => item.key === tObj.key);
							// 如果不存在则直接push
							if (tIsHasOption.length === 0) Match[ObjName + "Select"].push(tObj);
							// 按照sort数值进行排序
							fnObjForArraySort(Match[ObjName + "Select"], "sort");
						} else {
							Match[ObjName + "Select"] = [tObj];
						}
						// 选中数量加1
						Match.selectNum += 1;
						// 玩法全部选中的数量加1
						Match[AllSelectName] += 1;
					} else {
						// 组装数据，方便投注页面使用
						let tObj = {
							name: Match[ObjName][Option.name],
							award: Match[ObjName][Option.key],
							key: Option.key,
							sort: Option.sort,
						};
						// 将当前的玩法创建一个已选玩法数组，用于投注页面展示
						if (Match[ObjName + "Select"] && Match[ObjName + "Select"].length >= 0) {
							// 将当前玩法的选项从删除已选玩法数组
							Match[ObjName + "Select"].forEach((item, index) => {
								if (item.key === tObj.key) {
									delete Match[ObjName + "Select"][index]
								}
							});
							// 更新已选玩法数组
							Match[ObjName + "Select"] = fnDeepClone(Match[ObjName + "Select"].filter(item => item !==
								undefined));
						} else {
							// 重置
							Match[ObjName + "Select"] = [];
						}
						// 选中数量减1
						if (Match.selectNum > 0) Match.selectNum -= 1;
						// 玩法全部选中的数量减1
						if (Match[AllSelectName] > 0) Match[AllSelectName] -= 1;
					}
				}
			
				if (IsDo === "all" || IsDo === "second") {
					let tMatch = fnDeepClone(Match)
					// 当前赛事是否已添加到已选赛事选项中
					let tAMatchSelect = aMatchSelect.value[ObjName] ? aMatchSelect.value[ObjName] : [];
					// 是否已添加到选项中
					let tIsHas = false;
					for (let i = 0; i < tAMatchSelect.length; i++) {
						if (tAMatchSelect[i].id === Match.id) {
							// 当前赛事已选中数量大于0
							if (Match.selectNum > 0) {
								// 已添加则更新
								tIsHas = true;
								tAMatchSelect[i] = tMatch;
							} else {
								tAMatchSelect.splice(i, 1)
								break;
							}
						}
					}
					// 当前赛事已选中数量大于0 并且 未添加则添加
					if (Match.selectNum > 0 && !tIsHas) tAMatchSelect.push(tMatch);
				}
				
				fChangeDisabled()
				
				console.log(aMatchSelect.value);
			};
			
			/**
			 * [fSelectOptions 选择比赛选项]
			 * @param  {[Number]} Index [所属期次号下标]
			 * @param  {[Number]} IndexA [当前比赛下标]
			 * @param  {[Object]} Option [当前比赛选中的选项信息]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @param  {[String]} AllSelectName [当前比赛玩法全选的名称]
			 * @return {[Null]}
			 */
			const fSelectOptions = (Index, IndexA, Option, ObjName, AllSelectName) => {
				const tMatchToday = oMatch[sActive.value]['aMatch'][Index];
				const tMatch = tMatchToday["responseList"][IndexA];
				tMatch["issueNo"] = tMatchToday["issueNo"];
				fnHandleSelectData("all", tMatch, Option, ObjName, AllSelectName);
			};
			
			/**
			 * [fSelectAll 选择全部]
			 * @param  {[Number]} Index [所属期次号下标]
			 * @param  {[Number]} IndexA [当前比赛下标]
			 * @param  {[Object]} ObjName [当前比赛选项类型的对象名称]
			 * @param  {[String]} AllSelectName [当前比赛玩法全选的名称]
			 * @param  {[String]} PlayWay [当前比赛玩法的名称]
			 * @return {[Null]}
			 */
			const fSelectAll = (Index, IndexA, ObjName, AllSelectName, PlayWay) => {
				const tMatch = oMatch[sActive.value]['aMatch'][Index]["responseList"][IndexA];
				// 是否已经全选
				let t_bIsAll = tMatch[AllSelectName] === oCPData[PlayWay].length;
				// 循环选中
				oCPData[PlayWay] && oCPData[PlayWay].forEach(item => {
					// 没有全选时，已选中的跳过
					if (!t_bIsAll && tMatch[ObjName][item.select]) return;
					fSelectOptions(Index, IndexA, item, ObjName, AllSelectName)
				})
			};
			
			// 清除所有
			const fCleanAll = () => {
				oMatch[sActive.value]['aMatch'] = fnDeepClone(oMatch[sActive.value]['aMatch_BackUp']);
				aMatchSelect.value[sActive.value] = [];
				store.dispatch("allPlay/UBJDCSelected", aMatchSelect.value);
			};
			// 立即投注
			const fBet = () => {
				if (bIsDisabled.value) return;
				store.dispatch("allPlay/UBJDCSelected", aMatchSelect.value);
				store.dispatch("allPlay/UOrderData", {});
				router.push({
					path: '/betBD',
					query: {
						playWay: sActive.value
					}
				})
			};
			
			// 更多选项弹框--关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				if (dialogKey === "dialogC") {
					if (action === "confirm") {
						dialogC.showDialog = false;
						dialogC.isSure = true;
						fnIsHasHistory("/");
						return fn(false)
					} else if (action === "cancel") {
						dialogC.showDialog = false;
						dialogC.isSure = false;
						return fn(false)
					}
				}
			};
			
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory();
			};
			// fRight van-nav-bar 右侧点击事件
			const fRight = () => {
				router.push({
					path: '/generalTxt',
					query: {
						agreement: 'playWayBD'
					}
				})
			};
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (!dialogC.isSure) {
					// 白名单
					const WHITE_LIST = ['/betBD', '/generalTxt'];
					// 确定返回
					// 前往地址是否为投注地址--否
					if (WHITE_LIST.indexOf(to.path) === -1) {
						// Object.values:创建一个数组，其中包含对象中每个属性的值
						// 检查是否存在已选选项
						let t_bIsTrue = false;
						Object.values(aMatchSelect.value).forEach(item => {
							if (item.length > 0) {
								t_bIsTrue = true;
							}
						});
						if(t_bIsTrue) {
							// 赛事选项是否存在--是
							const timer = setTimeout(() => {
								dialogC.showDialog = true;
								clearTimeout(timer);
							}, 100)
							return false;
						}
					}
				} else {
					// 确定返回--是
					console.log(aMatchSelect.value)
					store.dispatch("allPlay/UBJDCSelected", null);
				}
			})
			
			
			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				console.log(route_meta);
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					fEcho(true);
					route_meta.resetType = '1'
				} else {
					route_meta.resetType = '1'
				}
			});
			return {
				OPTIONS,
				
				sActive,
				fOnRendered,
				
				oCPData,
				bIsDisabled,
				dialogC,
				oMatch,
				aMatchSelect,
				
				fSelectOptions,
				fSelectAll,
				fBeforeClose,

				fCleanAll,
				fBet,
				fLeft,
				fRight
			}
		}
	}
</script>

<style scoped lang="scss">
	.beijingball {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		background-image: url('~@/assets/images/img_011.png');
		background-size: 100% auto;
		background-repeat: no-repeat;

		.beijingball_none {
			background: #FAFBFF;
			box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			box-sizing: border-box;
		}

		.beijingball_body {
			padding: 0.2933rem 0.32rem 0;
			
			.beijingball_body_tab {
				height: 100%;
				overflow: scroll;
				.beijingball_day {
					background: #FAFBFF;
					box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
					border-radius: 0.2133rem;
				}
			}
		}

		.beijingball_bottom {
			padding: 0.2933rem 0.4rem;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.beijingball_bottom_left {
				display: flex;
				align-items: center;

				&>img {
					width: 0.5333rem;
				}

				&>span {
					margin-left: 0.32rem;
					font-size: 0.32rem;
					color: #383838;

					.beijingball_bottom_left_em2 {
						color: #FF7671
					}
				}
			}

			.beijingball_bottom_right {
				padding: 0.16rem 0.6133rem;
				background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
				border-radius: 0.4266rem;

				font-size: 0.3733rem;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 0.5333rem;
			}

			.beijingball_bottom_right--disabled {
				background: #FFABA8;
			}
		}

		.beijingball_tips {
			padding: 0.2666rem 0.64rem;
			margin-bottom: 0.5333rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}
	}
</style>
