<template>
	<div class="bet app_flex" :style="isApp ? 'padding-top: 1.3rem;box-sizing: border-box;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="投注" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>

		<div v-if="aMatchSelect.length > 0" class="bet_body app_scroll">
			<div class="bet_body_match" v-for="(item,index) in aMatchSelect" :key="index">
				<div class="bet_body_match_title">
					<span>{{item.hostShortName}}</span>
					<img class="bet_body_match_title_b" src="~@/assets/icon/icon-011.png">
					<span>{{item.guestShortName}}</span>

					<img class="bet_body_match_title_d" src="~@/assets/icon/icon-016.png" @click="fCloseMatch(index)">
				</div>
				<div class="bet_body_match_content">
					<div class="bet_body_match_content_a">
						<template v-if="item['footballSpfResponseSelect'] && item['footballSpfResponseSelect'].length > 0">
							<div v-for="(itemA) in item['footballSpfResponseSelect']" :key="itemA.key"
								class="bet_body_match_content_aa">
								<p>{{itemA.name}}</p>
								<p>{{itemA.award}}</p>
							</div>
						</template>
						<template
							v-if="item['footballRqspfResponseSelect'] && item['footballRqspfResponseSelect'].length > 0">
							<div v-for="(itemA) in item['footballRqspfResponseSelect']" :key="itemA.key"
								class="bet_body_match_content_aa bet_body_match_content_ab">
								<p>{{itemA.name}}</p>
								<p><em>{{item.rqspfPlate>0? '+' + item.rqspfPlate : item.rqspfPlate}}</em>{{itemA.award}}
								</p>
							</div>
						</template>
						<template v-if="item['footballBfResponseSelect'] && item['footballBfResponseSelect'].length > 0">
							<div v-for="(itemA) in item['footballBfResponseSelect']" :key="itemA.key"
								class="bet_body_match_content_aa">
								<p>{{itemA.name}}</p>
								<p>{{itemA.award}}</p>
							</div>
						</template>
						<template v-if="item['footballJqsResponseSelect'] && item['footballJqsResponseSelect'].length > 0">
							<div v-for="(itemA) in item['footballJqsResponseSelect']" :key="itemA.key"
								class="bet_body_match_content_aa">
								<p>{{itemA.name}}</p>
								<p>{{itemA.award}}</p>
							</div>
						</template>
						<template
							v-if="item['footballBqcspfResponseSelect'] && item['footballBqcspfResponseSelect'].length > 0">
							<div v-for="(itemA) in item['footballBqcspfResponseSelect']" :key="itemA.key"
								class="bet_body_match_content_aa">
								<p>{{itemA.name}}</p>
								<p>{{itemA.award}}</p>
							</div>
						</template>
					</div>
					<div class="bet_body_match_content_b" :class="{'bet_body_match_content_b_check': item['isDan']}"
						@click="fGuts(item)">
						胆
					</div>
				</div>

			</div>
		</div>
		<div v-else class="bet_body app_scroll">
			<van-empty class="app_none_empty" :image="require('@/assets/images/img_021.png')" description="请选择投注赛事" />
		</div>
		<div class="bet_foot">
			<div class="bet_foot_a">
				<span class="bet_foot_a_style">过关方式：</span>
				<div class="bet_foot_a_content">
					<template v-for="item in maxStrand" :key="item">
						<em v-if="minStrand < (item + 1)"
							:class="{'bet_foot_a_content_select' : aStrandSelect.indexOf(item + 1) !==-1}"
							@click="fSelectStrand(item+1)">
							{{item + 1}}串1
						</em>
					</template>
					<em v-if="bIsShowSingleStrand"
						:class="{'bet_foot_a_content_select' : aStrandSelect.indexOf(1) !==-1}"
						@click="fSelectStrand(1)">单关</em>
				</div>
			</div>
			<div class="yjdd" @click="toHY">
				一键到店
			</div>

		</div>


		<h-dialog-c title="联系店主" dialogKey="dialogC" :show="dialogC.showDialog" :showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText" @fBeforeClose="fBeforeCloseLq">
			<div class="footBall_tips" style="text-align: center">
				店主QQ：709674174
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory
	} from "@/utils/router.js"
	import {
		fnDeepClone,
		fnKeepFigures,
		fnCopyInnerText,
		uaFun
	} from "@/utils/public.js";

	import {
		jczqOptions as OPTIONS
	} from "@/utils/options.js";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import {
		apiPlanCalculate,
		apiPlanLotteryShop,
		apiPlanCreate,
		apiOrderPay,
		saveBetContent
	} from '@/api/allplay.js';
	export default {
		name: "Bet",
		components: {
			HDialogC
		},
		setup() {
			const isApp = uaFun()
			const router = useRouter(); //初始化路由
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;

			// 已选择数据
			const aSelectType = ["footballSpfResponseSelect", "footballRqspfResponse", "footballBfResponseSelect",
				"footballJqsResponseSelect", "footballBqcspfResponseSelect"
			];
			// 页面数据
			const oCPData = reactive({
				oNCData: {},
				oYCData: {
					dialogA: {
						showDialog: false,
						showCancelButton: false,
						showConfirmButton: false
					},
					dialogCA: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCB: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
					dialogCC: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "去支付佣金"
					},
					dialogCD: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
				}
			});

			// 方案详情
			const oPlanDetail = reactive({
				isDisableCreate: true, // 是否可以彩店出票
				shopName: "", // 绑定彩票店名称
				calculate: {
					multiple: 1, // 下注倍数
					minBetMultiple: 1, // 最低下注倍数
					minTotalFee: null, // 最低下注金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					totalFee: 0, // 方案投注金额
				}
			});
			// 理论最高奖金
			const calculate_prePrizeAmount = computed(() => {
				return oPlanDetail.calculate.prePrizeAmount * 100 * oPlanDetail.calculate.multiple / 100
			});
			// 理论最低奖金
			const calculate_prePrizeAmountMin = computed(() => {
				return oPlanDetail.calculate.prePrizeAmountMin * 100 * oPlanDetail.calculate.multiple / 100
			});
			// 方案投注金额
			const calculate_totalFee = computed(() => {
				return oPlanDetail.calculate.totalFee * 100 * oPlanDetail.calculate.multiple / 100
			});


			// 已选择赛事信息
			const aMatchSelect = ref(fnDeepClone(store.state.allPlay.FBSelected));
			console.log(aMatchSelect);
			// 流量APP选择赛事
			const aBetSelect = ref(fnDeepClone(store.state.allPlay.BETSelected));
			// 最大串关数量
			const maxStrand = computed(() => {
				let tAMatchSelect = aMatchSelect.value;
				let max = 8;
				for (let i = 0; i < tAMatchSelect.length; i++) {
					for (let j = 0; j < aSelectType.length; j++) {
						// 赛事的选项存在
						if (tAMatchSelect[i][aSelectType[j]] && tAMatchSelect[i][aSelectType[j]] > 0) {
							// 筛选最大串关
							switch (aSelectType[j]) {
								case 'footballSpfResponseSelect':
								case 'footballRqspfResponse':
									max = max <= 8 ? max : 8;
									break;
								case 'footballJqsResponseSelect':
									max = max <= 6 ? max : 6;
									break;
								case 'footballBfResponseSelect':
								case 'footballBqcspfResponseSelect':
									max = max <= 4 ? max : 4;
									break;
							}
						}
					}
				}
				max = max >= tAMatchSelect.length ? tAMatchSelect.length : max;
				max = max - 1 < 0 ? 0 : max - 1;
				return max;
			})
			// 最小串关数量
			const minStrand = ref(0);
			// 是否展示单关
			const bIsShowSingleStrand = computed(() => {
				let tAMatchSelect = aMatchSelect.value;
				// 是否存在非单关选项
				let isHasNoDg = false;
				if (tAMatchSelect.length === 0) {
					isHasNoDg = true;
				} else {
					for (let i = 0; i < tAMatchSelect.length; i++) {
						if (tAMatchSelect[i]["selectNoDg"] && tAMatchSelect[i]["selectNoDg"] > 0) {
							isHasNoDg = true;
						}
					}
				}
				return !isHasNoDg;
			})
			//	已选择过关方式
			const aStrandSelect = ref([]);

			// 最终合并数据
			const aMergeData = ref([]);

			// 如果平台赛事存在则反向解析
			const fReverse = () => {
				store.dispatch("allPlay/UBETSelected", {});
				if (aBetSelect.value && aBetSelect.value.itemList) {
					let tBet = aBetSelect.value.itemList;
					let tArr = [];
					for (let i = 0, j = tBet.length; i < j; i++) {
						let tObj = {
							gameNo: tBet[i].gameNo,
							guestShortName: tBet[i].guestShortName,
							hostShortName: tBet[i].hostShortName,
							isDan: tBet[i].isDan ? 1 : 0,
							issueNo: tBet[i].issueNo,
							id: tBet[i].matchId,
							rqspfPlate: tBet[i].rqspfPlate
						};
						let tOptions = tBet[i].itemDetailList;
						let selectDg = 0;
						let selectNoDg = 0;

						for (let n = 0, m = tOptions.length; n < m; n++) {
							for (let Key in OPTIONS) {
								//只遍历对象自身的属性，而不包含继承于原型链上的属性。
								if (Object.prototype.hasOwnProperty.call(OPTIONS, Key) === true) {
									if (OPTIONS[Key].lotteryResultEnum === tOptions[n].lotteryResultEnum &&
										OPTIONS[Key].lotterySubTypeEnum === tOptions[n].lotterySubTypeEnum) {
										let tSelectType = "";
										let tDgType = ""; // 单关类型
										switch (OPTIONS[Key].lotterySubTypeEnum) {
											case "JCZQ_SPF":
												tSelectType = "footballSpfResponseSelect";
												tDgType = "isSupportSpfDg";
												break;
											case "JCZQ_RQSPF":
												tSelectType = "footballRqspfResponse";
												tDgType = "isSupportRqspfDg";
												break;
											case "JCZQ_BF":
												tSelectType = "footballBfResponseSelect";
												tDgType = "isSupportBfDg";
												break;
											case "JCZQ_BQC":
												tSelectType = "footballBqcspfResponseSelect";
												tDgType = "isSupportBqcspfDg";
												break;
											case "JCZQ_JQS":
												tSelectType = "footballJqsResponseSelect";
												tDgType = "isSupportJqsDg";
												break;
										}
										if (tSelectType) {
											console.log(Key)
											let tOptionObj = {
												award: tOptions[n].award,
												key: Key,
												name: OPTIONS[Key].name
											}
											// 单关
											if (tBet[i][tDgType]) {
												selectDg += 1;
											} else {
												selectNoDg += 1;
											}

											if (tObj[tSelectType] && tObj[tSelectType].length) {
												tObj[tSelectType].push(tOptionObj)
											} else {
												tObj[tSelectType] = [];
												tObj[tSelectType].push(tOptionObj)
											}
										}
									}
								}
							}
						}
						tObj["selectDg"] = selectDg;
						tObj["selectNoDg"] = selectNoDg;
						tArr.push(tObj);
					}
					aMatchSelect.value = tArr;
					store.dispatch("allPlay/UFBSelected", []);
				}
			};
			fReverse();

			// 获取组合后的串关
			const fGetMixTypes = () => {
				const tAStrandSelect = aStrandSelect.value;
				// 赛事过关方式组合
				let tSMixTypes = "";
				if (tAStrandSelect.length === 1 && tAStrandSelect[0] === 1) {
					tSMixTypes = "单关"
				} else {
					for (let i = 0, j = tAStrandSelect.length; i < j; i++) {
						if (tSMixTypes) {
							tSMixTypes += "^" + tAStrandSelect[i] + "串1";
						} else {
							// 如果第一个是1
							if (tAStrandSelect[i] === 1) {
								tSMixTypes = "单关";
							} else {
								tSMixTypes = tAStrandSelect[i] + "串1";
							}
						}
					}
				}
				return tSMixTypes
			};
			// 计算
			const fCalculate = () => {
				console.log("再执行请求数据")
				let tSMixTypes = fGetMixTypes();
				Toast({
					type: "loading"
				});
				apiPlanCalculate({
					itemList: aMergeData.value,
					lotteryTypeEnum: "JCZQ",
					lotterySubTypeEnum: "JCZQ_HT",
					mixTypes: tSMixTypes,
					multiple: 1
				}).then((res) => {
					console.log(res);
					let tData = res.result;
					const orderData = store.state.allPlay.orderData;
					let tMultiple = orderData.multiple ? orderData.multiple : oPlanDetail.calculate.multiple;
					oPlanDetail.calculate.multiple = tMultiple <= tData.minBetMultiple ?
						tData.minBetMultiple : tMultiple;
					oPlanDetail.calculate.minBetMultiple = tData.minBetMultiple;
					oPlanDetail.calculate.minTotalFee = tData.minTotalFee > 0 ? tData.minTotalFee : null;
					oPlanDetail.calculate.prePrizeAmount = tData.prePrizeAmount;
					oPlanDetail.calculate.prePrizeAmountMin = tData.prePrizeAmountMin;
					oPlanDetail.calculate.totalFee = tData.totalFee;
				})
			};
			// 合并数据
			const fMergeData = () => {
				console.log("先执行合并数据")
				let tAMatchSelect = aMatchSelect.value;
				let tArr = [];
				tAMatchSelect.forEach((item) => {
					let tObj = {
						gameNo: item.gameNo,
						isDan: item.isDan ? 1 : 0,
						issueNo: item.issueNo,
						matchId: item.id,
						itemDetailList: []
					};

					aSelectType.forEach((itemA) => {
						if (item[itemA] && item[itemA].length > 0) {
							item[itemA].forEach(itemB => {
								const {
									lotteryResultEnum,
									lotterySubTypeEnum
								} = OPTIONS[itemB.key];
								let tObjOption = {
									award: itemB.award,
									lotteryResultEnum,
									lotterySubTypeEnum
								}
								tObj.itemDetailList.push(tObjOption)
							})
						}
					})
					tArr.push(tObj);

				})

				aMergeData.value = tArr;
				console.log(aMergeData.value,'niun9iuasdhaskjdjkashfkjsahjk');
				fCalculate()
			};
			// 关闭赛事(index: 当前赛事下标)
			const fCloseMatch = (index) => {
				let tAMatchSelect = aMatchSelect.value;
				let tCAMatchSelect = fnDeepClone(tAMatchSelect);
				tCAMatchSelect.splice(index, 1);
				tCAMatchSelect.forEach(item => {
					item.isDan = false;
				});
				aMatchSelect.value = tCAMatchSelect;
				// 同时更新store数据
				store.state.allPlay.FBSelected.splice(index, 1);
				store.dispatch("allPlay/UFBSelected", store.state.allPlay.FBSelected);

				fGetMinStrand();
			}
			// 选择比赛拖胆选项
			const fGuts = (match) => {
				let tAMatchSelect = aMatchSelect.value;
				// 赛事已选胆数量
				let danSelect = 0;
				for (let i = 0; i < tAMatchSelect.length; i++) {
					if (tAMatchSelect[i]["isDan"]) {
						danSelect += 1;
					}
				}
				if (danSelect === aMatchSelect.value.length - 1) {
					return Toast({
						message: "当前最多只能选" + danSelect + "个胆",
						position: 'bottom'
					});
				}

				match.isDan = !match.isDan;

				fGetMinStrand();
			};
			// 获取最小可设置的过关方式
			const fGetMinStrand = () => {
				let tAMatchSelect = aMatchSelect.value;
				// 赛事已选胆数量
				let danSelect = 0;
				for (let i = 0; i < tAMatchSelect.length; i++) {
					if (tAMatchSelect[i]["isDan"]) {
						danSelect += 1;
					}
				}
				minStrand.value = danSelect;
				fGetSelectStrand();
			};
			// 获取已选择的串关方式
			const fGetSelectStrand = () => {
				let tMaxStrand = maxStrand.value;
				let tMinStrand = minStrand.value;
				let tAStrandSelect = [];

				if (bIsShowSingleStrand.value) tAStrandSelect.push(1);

				for (let i = 1; i <= tMaxStrand; i++) {
					if (tMinStrand < (i + 1)) {
						tAStrandSelect.push((i + 1))
					}
				}
				aStrandSelect.value = tAStrandSelect;
				if (aStrandSelect.value.length === 0) {
					oPlanDetail.isDisableCreate = true;
					oPlanDetail.calculate.prePrizeAmount = 0; // 理论最高奖金
					oPlanDetail.calculate.prePrizeAmountMin = 0; // 理论最低奖金
					oPlanDetail.calculate.totalFee = 0; // 方案投注金额
					return;
				}
				oPlanDetail.isDisableCreate = false;
				fMergeData();
			};

			// 选择串关方式
			const fSelectStrand = (Value) => {
				let tCAStrandSelect = fnDeepClone(aStrandSelect.value);
				let tIdx = tCAStrandSelect.indexOf(Value);
				if (tIdx !== -1) {
					// 剩余最后一个串关方式则不可取消
					if (aStrandSelect.value.length === 1) return;
					tCAStrandSelect.splice(tIdx, 1);
				} else {
					tCAStrandSelect.push(Value);
				}
				tCAStrandSelect.sort();
				aStrandSelect.value = tCAStrandSelect;

				fMergeData();
			};
			/**
			 * fOperationOrder 操作订单
			 * @param {string} 发布：release;优化：optimize
			 * @return {null}
			 */
			// 操作订单 pType（发布：release;优化：optimize）
			const fOperationOrder = (pType) => {
				if (oPlanDetail.isDisableCreate) return Toast({
					message: "当前选择的赛事较少不支持下单",
					position: 'bottom'
				});
				const tMinTotalFee = oPlanDetail.calculate.minTotalFee;
				if (pType !== "optimize" && tMinTotalFee && (tMinTotalFee > calculate_totalFee.value)) {
					return Toast({
						message: "投注金额不能小于" + tMinTotalFee + "元",
						position: 'bottom'
					});
				} else {
					let tSMixTypes = fGetMixTypes();

					// 创建订单数据，并存储到vuex中
					let oOrderData = {
						itemList: aMergeData.value,
						lotterySubTypeEnum: "JCZQ_HT",
						lotteryTypeEnum: "JCZQ",
						mixTypes: tSMixTypes,
						aMixTypes: fnDeepClone(aStrandSelect.value),
						multiple: oPlanDetail.calculate.multiple,
						roleId: UserInfo.roleId,
						roleName: UserInfo.roleName,
						shopId: "1",
						sourceId: "",
						totalFee: calculate_totalFee.value,
						userId: UserInfo.userId,
						userName: UserInfo.realName,
						minTotalFee: tMinTotalFee,
					};
					console.log(oOrderData);
					store.dispatch("allPlay/UOrderData", oOrderData);
					router.push({
						path: '/'+pType
					});
				}
			};
			// 彩店出票
			const fCreateOrder = () => {
				if (oPlanDetail.isDisableCreate) return Toast({
					message: "当前选择的赛事较少不支持下单",
					position: 'bottom'
				});

				const tMinTotalFee = oPlanDetail.calculate.minTotalFee;
				if (tMinTotalFee && (tMinTotalFee > calculate_totalFee.value)) {
					return Toast({
						message: "投注金额不能小于" + tMinTotalFee + "元",
						position: 'bottom'
					});
				} else {
					Toast({
						type: "loading"
					});
					apiPlanLotteryShop({
						roleId: UserInfo.roleId,
						userId: UserInfo.userId,
						lotteryTypeEnum: "JCZQ"
					}).then(res => {
						let tData = res.result;
						let tShopName = "";
						if (tData.canBet) {
							for (let i = 0, j = tData.lotteryShopList.length; i < j; i++) {
								if (i === 0) {
									tShopName = "" + tData.lotteryShopList[i].shopName
								} else {
									tShopName = tShopName + "/" + tData.lotteryShopList[i].shopName
								}
							}
							oPlanDetail.shopName = tShopName;
							oCPData.oYCData.dialogCA.showDialog = true;
						}
					});
				}
			}

			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				console.log(action, oDialog, fn)
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						// 跳转至跟单页面
						console.log("跳转至跟单页面");
						oCPData.oYCData.dialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						// 关闭弹框
						console.log("关闭弹框");
						oCPData.oYCData.dialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCA") {
					if (action === "confirm") {
						// 确定创建订单
						let tSMixTypes = fGetMixTypes();
						Toast({
							type: "loading"
						});
						apiPlanCreate({
							canCopy: 0,
							itemList: aMergeData.value,
							lotterySubTypeEnum: "JCZQ_HT",
							lotteryTypeEnum: "JCZQ",
							mixTypes: tSMixTypes,
							multiple: oPlanDetail.calculate.multiple,
							profitProtectRate: 0,
							roleId: UserInfo.roleId,
							roleName: UserInfo.roleName,
							shopId: "1",
							sourceId: "",
							totalFee: calculate_totalFee.value,
							userId: UserInfo.userId,
							userName: UserInfo.realName
						}).then(res => {
							let tData = res.result ? res.result : {};
							oPayRelated.payDetail.availableAmount = tData.availableAmount ? tData
								.availableAmount : 0;
							oPayRelated.payDetail.totalFee = tData.totalFee ? tData.totalFee : 0;
							oPayRelated.payDetail.prePrizeAmount = tData.prePrizeAmount ? tData
								.prePrizeAmount : 0;
							oPayRelated.payDetail.prePrizeAmountMin = tData.prePrizeAmountMin ? tData
								.prePrizeAmountMin : 0;
							oPayRelated.payDetail.planId = tData.planId;
							oPayRelated.payDetail.isEnough = tData.isEnough;
							oCPData.oYCData.dialogCA.showDialog = false;
							store.dispatch("allPlay/UFBSelected", []);
							oPayRelated.showDialog = true;
							fn(false)
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogCC") {
					if (action === "confirm") {
						oCPData.oYCData.dialogCC.showDialog = false;
						fn(false);
						// 跳转支付佣金界面
						router.replace({
							path: '/recordGD',
							query: {
								redirect: 'mine'
							}
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCC.showDialog = false;
						fn(false);
					}
				} else if (dialogKey === "dialogCD") {
					if (action === "confirm") {
						oPayRelated.showDialog = false;
						oCPData.oYCData.dialogCD.showDialog = false;
						fn(false);
						// 跳转方案列表页
						router.replace({
							path: '/plan'
						})
					} else if (action === "cancel") {
						oCPData.oYCData.dialogCD.showDialog = false;
						fn(false);
					}
				}
			};
			/* fBeforeCloseActionSheetA ActionSheetA关闭之前
			 * action 操作的按钮（confirm：确定；cancel：取消；overlay：点击遮罩层）
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fBeforeCloseActionSheetA = (action, fn) => {
				if (action === 'confirm') {
					oPayRelated.showDialog = false;
					fn(false)
				} else if (action === 'cancel') {
					oCPData.oYCData.dialogCD.showDialog = true;
					fn(true)
				}
			};
			/* fSubmitActionSheetA 支付的动作面板中的支付按钮
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fSubmitActionSheetA = (fn) => {
				// 该订单是否可支付
				if (!oPayRelated.payDetail.isEnough) return;
				Toast({
					type: "loading"
				});
				// 订单支付
				apiOrderPay({
					balance: oPayRelated.payDetail.totalFee,
					otherFee: 0,
					payType: 1,
					planId: oPayRelated.payDetail.planId,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oPayRelated.showDialog = false;
					fn(false);
					let tData = res.result;
					// 是否支付成功
					if (tData["payResult"]) {
						// 成功
						Toast({
							message: tData.message ? tData.message : "支付成功",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						});
					} else if (tData["orderUnPayReasonEnum"] === "HAVE_UN_PAY_FEE") {
						// 失败:需要支付佣金--打开跳转到佣金弹框
						oCPData.oYCData.dialogCC.showDialog = true;
					} else {
						Toast({
							message: tData.message ? tData.message : "支付失败",
							position: 'bottom'
						});
						// 跳转到订单详情
						router.replace({
							path: '/planDetail',
							query: {
								id: oPayRelated.payDetail.planId,
								redirect: "home"
							}
						})
					}
				})
			};


			// 支付相关信息
			const oPayRelated = reactive({
				payDetail: {
					availableAmount: 0, // 店内余额
					totalFee: 0, // 方案金额
					prePrizeAmount: 0, // 理论最高奖金
					prePrizeAmountMin: 0, // 理论最低奖金
					planId: null, // 方案ID
					isEnough: false, // 是否可以支付
					lotteryType: "JCZQ",
				},
				oButton: {
					isShow: true,
					text: "确定"
				},
				showDialog: false, //是否展示弹框
				title: "彩店出票"
			});


			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/loginPw");
				store.dispatch("allPlay/UOrderData", {});
			};
			const toHY = () =>{
				console.log(aMergeData.value,aMatchSelect.value);



				let tAMatchSelect = aMatchSelect.value;
				let tArr = [];
				tAMatchSelect.forEach((item) => {
					let tObj = {
						gameNo: item.gameNo,
						isDan: item.isDan ? 1 : 0,
						issueNo: item.issueNo,
						matchId: item.id,
						itemDetailList: []
					};


						if (item['footballSpfResponseSelect'] && item['footballSpfResponseSelect'].length > 0) {
							item['footballSpfResponseSelect'].forEach(itemB => {
								const {
									lotteryResultEnum,
									lotterySubTypeEnum
								} = OPTIONS[itemB.key];
								let tObjOption = {
									award: itemB.award,
									lotteryResultEnum,
									lotterySubTypeEnum
								}
								tObj.itemDetailList.push(tObjOption)
							})
						}
						if (item['footballRqspfResponseSelect'] && item['footballRqspfResponseSelect'].length > 0) {
							item['footballRqspfResponseSelect'].forEach(itemB => {
								const {
									lotteryResultEnum,
									lotterySubTypeEnum
								} = OPTIONS[itemB.key];
								let tObjOption = {
									award: itemB.award,
									lotteryResultEnum,
									lotterySubTypeEnum
								}
								tObj.itemDetailList.push(tObjOption)
							})
						}
						if (item['footballBfResponseSelect'] && item['footballBfResponseSelect'].length > 0) {
							item['footballBfResponseSelect'].forEach(itemB => {
								const {
									lotteryResultEnum,
									lotterySubTypeEnum
								} = OPTIONS[itemB.key];
								let tObjOption = {
									award: itemB.award,
									lotteryResultEnum,
									lotterySubTypeEnum
								}
								tObj.itemDetailList.push(tObjOption)
							})
						}
						if (item['footballJqsResponseSelect'] && item['footballJqsResponseSelect'].length > 0) {
							item['footballJqsResponseSelect'].forEach(itemB => {
								const {
									lotteryResultEnum,
									lotterySubTypeEnum
								} = OPTIONS[itemB.key];
								let tObjOption = {
									award: itemB.award,
									lotteryResultEnum,
									lotterySubTypeEnum
								}
								tObj.itemDetailList.push(tObjOption)
							})
						}
						if (item['footballBqcspfResponseSelect'] && item['footballBqcspfResponseSelect'].length > 0) {
							item['footballBqcspfResponseSelect'].forEach(itemB => {
								const {
									lotteryResultEnum,
									lotterySubTypeEnum
								} = OPTIONS[itemB.key];
								let tObjOption = {
									award: itemB.award,
									lotteryResultEnum,
									lotterySubTypeEnum
								}
								tObj.itemDetailList.push(tObjOption)
							})
						}

					tArr.push(tObj);

				})

				aMergeData.value = tArr;







				let tSMixTypes = fGetMixTypes();
				aMatchSelect.value.forEach((e,i)=>{
					e.matchId = e.id
					aMergeData.value.forEach((x,j)=>{
						if(j == i){
							e.itemDetailList = x.itemDetailList
						}
					})
				})
				let tData = {
					lotteryTypeEnum:'JCZQ',
					lotterySubTypeEnum:"JCZQ_HT",
					mixTypes:tSMixTypes,
					multiple:oPlanDetail.calculate.multiple,
					itemList:aMatchSelect.value,
					expiredTime:aMatchSelect.value[0].deadlineStr
				}
				saveBetContent({
					content:JSON.stringify(tData)
				}).then(res=>{
					fnCopyInnerText( res.result)
					if(UserInfo.iosAccountInfo){
						var userAgent = navigator.userAgent.toLowerCase();//获取UA信息
						if(userAgent.indexOf("mjfc") != -1 || userAgent.indexOf("fcone") != -1){
							window.location.href ="lotteryshop://bet?betId=" + res.result
						}else {
							location.href =UserInfo.iosAccountInfo.h5Url + res.result
						}
					}else {
						dialogC.showDialog = true
					}

				})

			};

			// 弹框关闭之前
			const fBeforeCloseLq = (action, oDialog, ) => {
				console.log(action, oDialog);
				if(action == 'cancel'){
					dialogC.showDialog = false

				}else {
					let tData = fnCopyInnerText("709674174");
					if (tData) {
						Toast({
							message: '复制成功',
							position: 'bottom'
						});
						dialogC.showDialog = false
					} else {
						Toast({
							message: '复制成功',
							position: 'bottom'
						});
						dialogC.showDialog = false
					}
				}


			};
			const dialogC = reactive({
				isSure: false, // 确定退出
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "复制"
			});


			fGetSelectStrand();
			return {
				oCPData,
				toHY,
				dialogC,
				aMatchSelect,
				maxStrand,
				minStrand,
				bIsShowSingleStrand,
				aStrandSelect,

				oPayRelated,
				oPlanDetail,
				fBeforeCloseLq,

				calculate_prePrizeAmount,
				calculate_prePrizeAmountMin,
				calculate_totalFee,

				fCloseMatch,
				fGuts,
				fSelectStrand,

				fOperationOrder,
				fCreateOrder,

				fBeforeCloseActionSheetA,
				fSubmitActionSheetA,
				fBeforeClose,
				fLeft,
				isApp,
				fnKeepFigures
			}
		}
	}
</script>

<style scoped lang="scss">
	.bet {
		width: 100%;
		height: 100%;
		background: #F1F2F7;

		.bet_body {
			padding: 0.2933rem 0.32rem 0;

			.bet_body_match {
				background: #FFFFFF;
				border-radius: 0.2133rem;
				margin-bottom: 0.4266rem;

				.bet_body_match_title {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0.32rem 0;
					background: #FAFAFA;
					border-radius: 0.2133rem 0.2133rem 0px 0px;

					&>span {
						flex: 1;
						font-size: 0.4266rem;
						font-weight: 500;
						color: #383838;
					}

					&>span:first-child {
						text-align: right;
					}

					&>span:last-child {
						text-align: left;
					}

					.bet_body_match_title_b {
						width: 0.3733rem;
						height: auto;
						margin: 0 0.32rem;
					}

					.bet_body_match_title_d {
						position: absolute;
						right: 0.4rem;
						top: 0;
						bottom: 0;
						margin: auto 0;
						width: 0.4266rem;
						height: 0.4266rem;
					}
				}

				.bet_body_match_content {
					padding: 0.4rem;
					border-radius: 0px 0px 0.2133rem 0.2133rem;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.bet_body_match_content_a {
						flex: 1;
						display: flex;
						flex-wrap: wrap;
						align-items: center;

						.bet_body_match_content_aa {
							width: 2.4533rem;
							height: 1.1733rem;
							box-sizing: border-box;
							margin-right: 0.08rem;
							background: #FFE4E3;
							border-radius: 0.1066rem;
							border: 0.0266rem solid #E2335D;
							display: flex;
							align-items: center;
							flex-direction: column;
							justify-content: center;
							margin-bottom: 0.1066rem;

							&>p:first-child {
								font-size: 0.4rem;
								font-weight: 500;
								color: #383838;
								line-height: 0.5333rem;
							}

							&>p:last-child {
								margin-top: 0.0266rem;
								font-size: 0.3733rem;
								font-weight: bold;
								color: #E2335D;
								line-height: 0.4266rem;

								display: flex;
								align-items: center;
							}
						}

						.bet_body_match_content_ab {
							&>p:last-child {
								&>em {
									display: flex;
									align-items: center;
									max-height: 0.3733rem;
									box-sizing: border-box;
									padding: 0.0266rem 0.16rem;
									background: #E2335D;
									border-radius: 0.1866rem;
									border: 0.0266rem solid #E2335D;
									margin-right: 0.0533rem;
									font-size: 0.2666rem;
									font-weight: bold;
									color: #FFFFFF;
									line-height: 0.2933rem;
								}
							}
						}
					}

					.bet_body_match_content_b {
						display: flex;
						align-items: center;
						justify-content: center;

						width: 0.9333rem;
						height: 1.1733rem;
						box-sizing: border-box;
						background: #FAFAFA;
						border-radius: 0.1066rem;
						border: 0.0266rem solid #F1F1F1;

						font-size: 0.3733rem;
						font-weight: 500;
						color: #383838;
					}

					.bet_body_match_content_b_check {
						background: #FED530;
						border-color: #F1F1F1;
						border-radius: 0.1066rem;
					}
				}
			}
		}

		.bet_foot {
			position: relative;
			padding: 0.4rem 0.4rem ;
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0px 0px;

			.bet_foot_a {
				padding-bottom: 0.4rem;
				display: flex;
				align-items: flex-start;

				border-bottom: 0.0266rem solid #FAFAFA;

				.bet_foot_a_content {
					margin-top: -0.2666rem;
					flex: 1;

					display: flex;
					flex-wrap: wrap;
					align-items: center;

					&>em {
						margin-top: 0.2666rem;
						padding: 0 0.4rem;

						border: 0.0266rem solid #FF7671;
						background: #FFFFFF;
						border-radius: 0.1066rem;

						font-size: 0.32rem;
						font-weight: 400;
						color: #FF7671;
						line-height: 0.4533rem;
						margin-right: 0.1066rem;
					}

					.bet_foot_a_content_select {
						background: #FF7671;
						color: #FFFFFF;
					}
				}
				.bet_foot_a_style{
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;
				}
				.bet_foot_a_optimize {
					font-size: 0.3733rem;
					font-weight: 600;
					color: #383838;
				}
			}

			.bet_foot_b {
				padding: 0.2133rem 0;
				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 0.3733rem;
				font-weight: 400;
				color: #979797;

				&>em {
					margin-left: 0.1066rem;
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
				}
			}

			.bet_foot_c {
				padding: 0.24rem 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.3733rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5333rem;

				&>em {
					margin-left: 0.1066rem;
					font-size: 0.48rem;
					font-weight: bold;
					color: #E2335D;
				}
			}

			.bet_foot_d {
				padding: 0.16rem 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.bet_foot_d_a {

					display: flex;
					align-items: center;
					justify-content: center;

					&>span {
						box-sizing: border-box;
						display: inline-block;
						text-align: center;
						width: 2.6933rem;
						height: 1.0133rem;
						line-height: 1.0133rem;
					}

					.bet_foot_d_a_a {
						background: #FFFFFF;
						border-radius: 0.6666rem 0px 0px 0.6666rem;
						border: 0.0266rem solid #e2335d;

						font-size: 0.3733rem;
						font-weight: 500;
						color: #E2335D;
					}

					.bet_foot_d_a_b {
						background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
						border-radius: 0 0.6666rem 0.6666rem 0;

						font-size: 0.3733rem;
						font-weight: 500;
						color: #FFFFFF;
					}

					// .bet_foot_d_a--disabled {
					// 	background: #FFABA8;
					// }
				}
			}
		}

		.bet_foot::after {
			content: "";
			position: absolute;
			top: -0.5866rem;
			left: 0;
			width: 100%;
			height: 0.5866rem;
			background: linear-gradient(180deg, rgba(241, 242, 247, 0) 0%, #E6E7EA 100%);
		}

		.bet_tips {
			padding: 0.2666rem 0.64rem 0.88rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}

		.bet_tips_a {
			padding: 0.2666rem 0.64rem 0.8rem;

			&>p {
				margin-top: 0.8rem;
				font-size: 0.4266rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.5866rem;
			}
		}

		.bet_dialogCD {
			text-align: center;
		}
	}
	.yjdd{
		width: 100%;
		height: 0.853rem;
		background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
		border-radius: 0.427rem;
		font-size: 0.373rem;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 0.853rem;
		text-align: center;
	}
</style>
